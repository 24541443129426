import React from 'react';
import {motion} from "framer-motion";
import {Svg, Images} from "assets/images";


export const Banner = () => {
    const animVariant = {
        hidden: {
            y: 100,
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
        }
    };

    return (
        <div className='fleet-forest-banner'>
            <div className='overflow-hidden'>
                <motion.div
                    className="logo-title-description-block"
                    initial="hidden"
                    whileInView='visible'
                    variants={animVariant}
                    viewport={{amount: .2, once: true}}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                    }}
                >
                    <Svg.FleetForestLogo
                        className='fleet-logo'
                    />

                    <h1 className='fleet-forest-banner-title'>
                        Made by Carriers for Carriers and Truckers
                    </h1>

                    <p className='fleet-forest-banner-description min-1279'>
                        Designed to help you manage your trucks, drivers, loads and see your profits in real time.
                    </p>
                </motion.div>
            </div>


            <div className="fleet-forest-banner-image">
                <img src={Images.FleetForestBanner} alt="banner fleet forest"/>
            </div>
        </div>

    );
};



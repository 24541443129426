import React from 'react';
import {
    Banner,
    Challenges, InfoAboutProjects,
    KeyFeatures,
    LogoDesignAndBranding,
    UserPersonas
} from "../fragments";
import {Images} from "assets/images";
import {AboutSomething} from "fragments";
import {challengesCard, fleetMobileScreens, fleetTabletScreens} from "../fragments/fleetForest/constants";
import {StrategyCreativity} from "components";
import {userPersonasCard} from "../fragments/constants";

export const FleetForest = () => {
    return (
        <div className='fleet-forest-project-page'>
            <Banner/>

            <InfoAboutProjects
                client={["Fleetforest"]}
                industry={["Logistic and Transportation"]}
                deliverables={
                    [
                        "Web, iOS and Android Apps",
                        "Branding, Architecture And UI/UX Design",
                        "Subscription based SaaS Model Development",
                        "Functional and Non-Functional Requirements"
                    ]}
            />

            <div className="the-brief-key-features-wrapper">
                <AboutSomething
                    className="the-brief-wrapper"
                    title="The Brief"
                    img={Images.theBrief}
                    description="FleetForest is a SaaS solution designed specifically for the unique operational
                    needs of carrier companies. Through its integrated mobile application, it offers carrier
                     companies a seamless way to streamline their freight transportation operations. The platform
                      aids in managing admin, drivers, payroll, equipment, load costs, and calculating load profit
                      s to enhance overall operational visibility and profitability."
                    link={{name: 'Visit website', href: 'https://fleetforest.com/'}}
                />
                <KeyFeatures/>
            </div>

            <Challenges challengesCard={challengesCard}/>

            <div className="road-map-wrapper">
                <div className="road-map-image">
                    <img src={Images.RoadMap} alt="Road Map"/>
                </div>
            </div>

            <LogoDesignAndBranding/>

            <UserPersonas
                className='user-persons-fleet-forest'
                userPersonasCard={userPersonasCard}/>

            <section className="about-fleet-project-section">
                <div className='fleet-ui-design'>
                    <h3 className='ui-design-title'>
                        UI Design
                    </h3>

                    <AboutSomething
                        className='truck-management-wrapper'
                        title='Truck Management'
                        description="Enhance your fleet's performance with our comprehensive Truck Management
                        tools. From maintenance schedules to fuel tracking, gain insights into every aspect of
                         your truck's operation. Maximize efficiency and reduce costs, keeping your fleet running
                         smoothly."
                        link={{name: 'Visit website', href: 'https://fleetforest.com/trucks'}}
                        img={Images.FleetUiDesign}
                    />
                </div>

                <div className='fleet-ui-design'>
                    <AboutSomething
                        className='truck-management-wrapper'
                        title='Load Management'
                        description="Optimize and streamline your load operations with our Load Management feature.
                         Efficiently plan, schedule, and track each load, ensuring timely deliveries. Our system
                          adapts to changes in real-time, keeping you informed and in control."
                        link={{name: 'Visit website', href: 'https://fleetforest.com/loads'}}
                        img={Images.LoadManagement}
                    />
                </div>

                <div className="container">
                    <div className="fleet-tablet-screens-wrapper">
                        <h3 className='fleet-tablet-screens-title'>
                            Tablet Screens
                        </h3>

                        <div className="fleet-tablet-screens-box">
                            {fleetTabletScreens.map(({img, className}, i) => (
                                <div className={`fleet-single-tablet ${className ? className : ''}`} key={i}>
                                    <img src={img} alt="tablet"/>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="fleet-tablet-screens-wrapper">
                        <h3 className='fleet-tablet-screens-title'>
                            Mobile Screens
                        </h3>

                        <div className="fleet-tablet-images-box">
                            {fleetMobileScreens.map(({img, className}, i) => (
                                <div className={`fleet-single-mobile ${className ? className : ''}`} key={i}>
                                    <img src={img} alt="tablet"/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <div className="application-for-drivers-conclusion">
                <AboutSomething
                    className='application-for-drivers'
                    title='Application for Drivers'
                    description="The FleetForest mobile app for drivers is designed to streamline their workday.
                    Once an admin, manager, or dispatcher assigns a load, drivers can immediately see it on their
                    mobile device. The app allows drivers to upload documents, mark loads as complete, and view
                    their payroll information, making it an essential tool for on-the-go management and communication
                    within the logistics workflow."
                    img={Images.ApplicationForDriversImage}
                />

                <AboutSomething
                    className='application-for-drivers conclusion'
                    title='Conclusion'
                    description="In conclusion, FleetForest offers a comprehensive suite of tools designed to modernize
                     and streamline the logistics and management processes for carrier companies.
                      With its integrated mobile app, drivers can easily access and manage loads, documents,
                      and payroll, ensuring efficiency and communication within the fleet. FleetForest stands
                       as a pivotal solution for companies looking to enhance operational efficiency and driver
                       satisfaction."
                    secondDescription="We started by understanding the challenges that operators and drivers face.
                     It was ike solving a puzzle to help them work better. "
                    img={Images.FleetConclusionImage}
                />
            </div>

            <StrategyCreativity/>

        </div>
    );
};


import React from 'react';
import {motion} from 'framer-motion'
import {Images} from "assets/images";

export const BannerStarfish = () => {
    const titleAnimVariant = {
        visible: {
            y: 0,
        },
        hidden: {
            y: 35
        },
    }
    return (
        <div className='starfish-banner-wrapper'>
            <div className="container">
                <div className="starfish-banner-box">
                    <div className="starfish-title-description-block">
                        <div className='overflow-hidden'>
                            <motion.h1
                                className="starfish-banner-title"
                                initial='hidden'
                                whileInView='visible'
                                viewport={{amount: .2, once: true}}
                                variants={titleAnimVariant}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                    repeatDelay: 1
                                }}
                            >
                                Starfish
                            </motion.h1>
                        </div>
                        <div className='overflow-hidden'>
                            <motion.p
                                className='starfish-banner-description'
                                initial='hidden'
                                whileInView='visible'
                                viewport={{amount: .2, once: true}}
                                variants={titleAnimVariant}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                    repeatDelay: 1
                                }}
                            >
                                Starfish is a software system that runs in the cloud and connects to Amazon,
                                eBay, Walmart, Google and Erply to synchronize product quantities and create orders.
                            </motion.p>
                        </div>

                    </div>

                    <div className="starfish-banner-image-block">
                        <img src={Images.StarfishBanner} alt='Starfish Banner'/>
                    </div>
                </div>
            </div>
        </div>
    );
};


import React from 'react';
import {Card} from "components";

export const Challenges = ({challengesCard, classNameWrapper}) => {
    return (
        <div className={`challenges-wrapper ${classNameWrapper ? classNameWrapper : ''}`}>
            <div className="container">
                <div className="challenges-box">
                    <h3 className='challenges-title'>
                        Challenges
                    </h3>

                    <div className="challenges-cards">
                        {challengesCard?.map(({text, title}, i) => (
                            <Card
                                index={i}
                                classNameInView='challenges-single-card_InView'
                                className='challenges-single-card'
                                numbered={i + 1}
                                text={text}
                                title={title}
                                key={i}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};


import React from 'react';

export const InfoAboutProjects = ({className, fontColor, client, industry, deliverables}) => {
    return (
        <div className={`info-fleet-wrapper ${className}`}>
            <div className="container">
                <div className='info-fleet-box'>
                    <div className="fleet-infos-block-client-industry">
                        <div className='fleet-infos-block fleet-infos-block-client'>
                            <h4 className='fleet-info-title' style={{color: fontColor ? fontColor : ''}}>
                                Client
                            </h4>

                            <ul className='info-list-block'>
                                {client?.map((item, i) => (
                                    <li
                                        key={i}
                                        className='fleet-info-list-item'
                                        style={{color: fontColor ? fontColor : ''}}
                                    >
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className='fleet-infos-block fleet-infos-block-industry'>
                            <h4 className='fleet-info-title' style={{color: fontColor ? fontColor : ''}}>
                                Industry
                            </h4>

                            <ul className='info-list-block'>
                                {industry?.map((item, i) => (
                                    <li
                                        key={i}
                                        className='fleet-info-list-item'
                                        style={{color: fontColor ? fontColor : ''}}
                                    >
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className='fleet-infos-block fleet-infos-block-deliverables'>
                        <h4 className='fleet-info-title' style={{color: fontColor ? fontColor : ''}}>
                            Deliverables
                        </h4>

                        <ul className='info-list-block info-list-block-dev info-list-deliverables'>
                            {deliverables?.map((item, i) => (
                                <li
                                    key={i}
                                    className='fleet-info-list-item'
                                    style={{color: fontColor ? fontColor : ''}}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>


                </div>
            </div>
        </div>

    );
};


import React, {Children, cloneElement, useRef} from 'react';
import {InView} from "react-intersection-observer";

export const ScrollWrapper = ({className, children, title, onChange,wrapperRef,  ...props}) => {

    const childrenWithRef = Children.map(children, child => {
        if (React.isValidElement(child)) {
            return cloneElement(child, { ref: wrapperRef });
        }
        return child;
    });

    return (
        <InView
            {...props}
            className={`scroll-wrapper ${className ? className : ''}`}
            onChange={onChange}

        >
            <div>
                {childrenWithRef}
            </div>
        </InView>
    );
};


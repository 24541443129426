import React from 'react';
import {TermsPrivacyPolicy} from "../../fragments/termsPrivacyPolicy";
import {privacyPolicy} from "./fragments/constants";

export const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-page">
            <TermsPrivacyPolicy item={privacyPolicy}/>
        </div>
    );
};


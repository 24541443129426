import {ReactComponent as Twitter2Svg} from './svg/social/Twitter2Svg.svg'
import {ReactComponent as LinkedinSvg} from './svg/social/LinkedinSvg.svg'
import {ReactComponent as FacebookSvg} from './svg/social/FacebookSvg.svg'
import {ReactComponent as InstagramSvg} from './svg/social/InstagramSvg.svg'
import {ReactComponent as HomeBannerSvg} from './svg/HomeBannerSvg.svg'
import {ReactComponent as SendSvg} from './svg/Send.svg'
import {ReactComponent as SquareScg} from './svg/Square.svg'
import {ReactComponent as WebProgramming} from './svg/WebProgramming.svg'
import {ReactComponent as Brain} from './svg/Brain.svg'
import {ReactComponent as Curve} from './svg/Curve.svg'
import {ReactComponent as Logistics} from './svg/industryCard/Logistics.svg'
import {ReactComponent as Entertainment} from './svg/industryCard/Entertainment.svg'
import {ReactComponent as Food} from './svg/industryCard/Food.svg'
import {ReactComponent as Healthcare} from './svg/industryCard/Healthcare.svg'
import {ReactComponent as Retail} from './svg/industryCard/Retail.svg'
import {ReactComponent as Insurance} from './svg/industryCard/Insurance.svg'
import {ReactComponent as QuoteSvg} from './svg/Quote.svg'
import {ReactComponent as FooterLogo} from './svg/FooterLogo.svg'
import {ReactComponent as LogoSvg} from './svg/Logo.svg'
import {ReactComponent as BurgerMenu} from './svg/BurgerMenu.svg'
import {ReactComponent as CheckSvg} from './svg/CheckSvg.svg'
import {ReactComponent as ArrowSvg} from './svg/ArrowSvg.svg'
import {ReactComponent as AttachFileSvg} from './svg/AttachFileSvg.svg'
import {ReactComponent as FleetForestLogo} from './svg/fleetForest/FleetForestLogo.svg'
import {ReactComponent as FleetForestLogoGray} from './svg/fleetForest/FleetForestLogoGray.svg'
import {ReactComponent as FontBold} from './svg/fleetForest/FontBold.svg'
import {ReactComponent as FontLight} from './svg/fleetForest/FontLight.svg'
import {ReactComponent as StarfishProblemIcon} from './svg/starfishPage/StarfishProblemIcon.svg'
import {ReactComponent as StarfishSolutionIcon} from './svg/starfishPage/StarfishSolutionIcon.svg'
import {ReactComponent as ChallengesStarfishSvg} from './svg/starfishPage/ChallengesStarfishSvg.svg'
import {ReactComponent as MenuMangoLogo} from './svg/menuMango/MenuMangoLogo.svg'
import {ReactComponent as MenuMangoLogo2} from './svg/menuMango/MenuMangoLogo2.svg'
import {ReactComponent as TherapyLakeLogo} from './svg/therapyLake/TherapyLakeLogo.svg'
import {ReactComponent as LogoBy} from './svg/by/LogoBy.svg'
import {ReactComponent as AecoLogo} from './svg/aeco/AecoLogo.svg'
import {ReactComponent as CloseSvg} from './svg/CloseSvg.svg'
import {ReactComponent as PoloLogo} from './svg/polo/PoloLogo.svg'
import {ReactComponent as ReactjsSvg} from './svg/ourServices/tabs/ReactjsSvg.svg'
import {ReactComponent as NodeJsSvg} from './svg/ourServices/tabs/NodeJsSvg.svg'
import {ReactComponent as SwiftSvg} from './svg/ourServices/tabs/SwiftSvg.svg'
import {ReactComponent as NginxSvg} from './svg/ourServices/tabs/NginxSvg.svg'
import {ReactComponent as NextJsSvg} from './svg/ourServices/tabs/NextJsSvg.svg'
import {ReactComponent as WordpressSvg} from './svg/ourServices/tabs/WordpressSvg.svg'
import {ReactComponent as KotlinSvg} from './svg/ourServices/tabs/KotlinSvg.svg'
import {ReactComponent as MongoDBSvg} from './svg/ourServices/tabs/MongoDBSvg.svg'
import {ReactComponent as RedisSvg} from './svg/ourServices/tabs/RedisSvg.svg'
import {ReactComponent as DynamoDBSvg} from './svg/ourServices/tabs/DynamoDBSvg.svg'
import {ReactComponent as MySQLSvg} from './svg/ourServices/tabs/MySQLSvg.svg'
import {ReactComponent as PostgreSQLSvg} from './svg/ourServices/tabs/PostgreSQLSvg.svg'
import {ReactComponent as Elasticsearch} from './svg/ourServices/tabs/ElasticsearchSvg.svg'
import {ReactComponent as AWSSvg} from './svg/ourServices/tabs/AWSSvg.svg'
import {ReactComponent as AzureSvg} from './svg/ourServices/tabs/AzureSvg.svg'
import {ReactComponent as GoogleSvg} from './svg/ourServices/tabs/GoogleSvg.svg'
import {ReactComponent as NvidiaSvg} from './svg/ourServices/tabs/NvidiaSvg.svg'
import {ReactComponent as TransformSvg} from './svg/ourServices/tabs/TransformSvg.svg'
import {ReactComponent as PyTorchSvg} from './svg/ourServices/tabs/PyTorchSvg.svg'
import {ReactComponent as ChatGPTSvg} from './svg/ourServices/tabs/ChatGPTSvg.svg'
import {ReactComponent as GoogleCloudSvg} from './svg/ourServices/tabs/GoogleSvgCloud.svg'
import {ReactComponent as SiriSvg} from './svg/ourServices/tabs/SiriSvg.svg'
import {ReactComponent as DockerSvg} from './svg/ourServices/tabs/DockerSvg.svg'
import {ReactComponent as KubernetesSvg} from './svg/ourServices/tabs/KubernetesSvg.svg'
import {ReactComponent as GitSvg} from './svg/ourServices/tabs/GitSvg.svg'
import {ReactComponent as FDPSvg} from './svg/FDPSvg.svg'
import {ReactComponent as FMSvg} from './svg/FMSvg.svg'
import {ReactComponent as TTSvg} from './svg/TTSvg.svg'
import {ReactComponent as FTSvg} from './svg/FTSvg.svg'
import {ReactComponent as TimeManagementSvg} from './svg/TimeManagementSvg.svg'
import {ReactComponent as LocationSvg} from './svg/LocationSvg.svg'
import {ReactComponent as IterationSvg} from './svg/IterationSvg.svg'

// images
import HomeTab1 from './img/HomeTab1.png'
import BusinessPhoto1 from './img/BusinessPhoto1.png'
import BusinessPhoto2 from './img/BusinessPhoto2.png'
import Armat from './img/ourProjects/Armat.png'
import Fleetforest from './img/ourProjects/Fleetforest.png'
import Menumango from './img/ourProjects/Menumango.png'
import TheraphyLake from './img/ourProjects/TheraphyLake.png'
import Starfish from './img/ourProjects/Starfish.png'
import Aeco from './img/ourProjects/Aeco.png'
import Polo from './img/ourProjects/Polo.png'
import ByConsulting from './img/ourProjects/ByConsulting.png'
import ClientPhoto from './img/ClientPhoto.png'
import aboutAs from './img/aboutUsPage/aboutAs.png'
import Member from './img/Member.png'
import MainServices from './img/ourServices/MainServices.png'
import SoftDev from './img/ourServices/SoftDev.png'
import MobileAppSvg from './img/ourServices/MobileAppSvg.png'
import IntegrationsSvg from './img/ourServices/IntegrationsSvg.png'
import AutSoftSvg from './img/ourServices/AutSoftSvg.png'
import AiApsSvg from './img/ourServices/AiApsSvg.png'
import BlogImage from './img/BlogImage.png'
import theBrief from './img/fleetForest/theBrief.png'
import RoadMap from './img/fleetForest/RoadMap.png'
import UserMark from './img/UserMark.png'
import UserAlex from './img/UserAlex.png'
import UserDarlence from './img/UserDarlence.png'
import UserEmma from './img/UserEmma.png'
import FleetForestBanner from './img/fleetForest/FleetForestBanner.png'
import LoadManagement from './img/fleetForest/LoadManagement.png'
import FleetUiDesign from './img/fleetForest/FleetUiDesign.png'
import LogoDesignAndBrandingFleet1440 from './img/fleetForest/LogoDesignAndBrandingFleet1440.png'
import LogoDesignAndBrandingFleet767 from './img/fleetForest/LogoDesignAndBrandingFleet767.png'
import LogoDesignAndBrandingFleet344 from './img/fleetForest/LogoDesignAndBrandingFleet344.png'
import FleetTablet1 from './img/fleetForest/FleetTablet1.png'
import FleetTablet2 from './img/fleetForest/FleetTablet2.png'
import FleetTablet3 from './img/fleetForest/FleetTablet3.png'
import FleetMobile1 from './img/fleetForest/FleetMobile1.png'
import FleetMobile2 from './img/fleetForest/FleetMobile2.png'
import FleetMobile3 from './img/fleetForest/FleetMobile3.png'
import ApplicationForDriversImage from './img/fleetForest/ApplicationForDriversImage.png'
import FleetConclusionImage from './img/fleetForest/FleetConclusionImage.png'
import AboutPlatform from './img/starfish/AboutPlatform.png'
import WorkflowStarfish from './img/starfish/WorkflowStarfish.png'
import StarfishBanner from './img/starfish/StarfishBanner.png'
import StarfishScreen1 from './img/starfish/StarfishScreen1.png'
import StarfishScreen2 from './img/starfish/StarfishScreen2.png'
import StarfishScreen3 from './img/starfish/StarfishScreen3.png'
import StarfishBackground from './img/starfish/StarfishBackground.png'
import StarfishConclusion from './img/starfish/StarfishConclusion.png'
import UiUxStarfish1440 from './img/starfish/UiUxStarfish1440.png'
import UiUxStarfish767 from './img/starfish/UiUxStarfish767.png'
import UiUxStarfish375 from './img/starfish/UiUxStarfish375.png'
import BannerMenuMango1440 from './img/menuMango/BannerMenuMango1440.png'
import BannerMenuMango767 from './img/menuMango/BannerMenuMango767.png'
import BannerMenuMango375 from './img/menuMango/BannerMenuMango375.png'
import LogoDesignAndBrandingMenuMango1440 from './img/menuMango/LogoDesignAndBrandingMenuMango1440.png'
import LogoDesignAndBrandingMenuMango767 from './img/menuMango/LogoDesignAndBrandingMenuMango767.png'
import LogoDesignAndBrandingMenuMango375 from './img/menuMango/LogoDesignAndBrandingMenuMango375.png'
import TheBriefMenuMango from './img/menuMango/TheBriefMenuMango.png'
import BriefProblemBackMenuMango from './img/menuMango/BriefProblemBackMenuMango.png'
import TypographyMenuMango from './img/menuMango/TypographyMenuMango.png'
import UserPersonasBackMenuMango from './img/menuMango/UserPersonasBackMenuMango.png'
import MenuMangoMacbook from './img/menuMango/MenuMangoMacbook.png'
import MenuMangoScreens1440 from './img/menuMango/MenuMangoScreens1440.png'
import MenuMangoScreens778 from './img/menuMango/MenuMangoScreens778.png'
import MenuMangoScreens375 from './img/menuMango/MenuMangoScreens375.png'
import ConclusionMenuMango from './img/menuMango/ConclusionMenuMango.png'
import MenuMangoProjectProcess from './img/menuMango/MenuMangoProjectProcess.png'
import TherapyLakeBanner from './img/therapyLake/TherapyLakeBanner.png'
import TherapyLakeBanner375 from './img/therapyLake/TherapyLakeBanner375.png'
import TherapyLakeWorkflow from './img/therapyLake/TherapyLakeWorkflow.png'
import LogoAndBrandingTherapyLake1440 from './img/therapyLake/LogoAndBrandingTherapyLake1440.png'
import LogoAndBrandingTherapyLake767 from './img/therapyLake/LogoAndBrandingTherapyLake767.png'
import LogoAndBrandingTherapyLake375 from './img/therapyLake/LogoAndBrandingTherapyLake375.png'
import MacbookProTherapyLake from './img/therapyLake/MacbookProTherapyLake.png'
import TherapyLakeScreen from './img/therapyLake/TherapyLakeScreen.png'
import TheBriefTherapyLake from './img/therapyLake/TheBriefTherapyLake.png'
import TherapyLakeMobileScreen1 from './img/therapyLake/TherapyLakeMobileScreen1.png'
import TherapyLakeMobileScreen2 from './img/therapyLake/TherapyLakeMobileScreen2.png'
import TherapyLakeMobileScreen3 from './img/therapyLake/TherapyLakeMobileScreen3.png'
import ConclusionTherapyLake from './img/therapyLake/ConclusionTherapyLake.png'
import BackgroundTherapyLake1 from './img/therapyLake/BackgroundTherapyLake1.png'
import BackgroundTherapyLake2 from './img/therapyLake/BackgroundTherapyLake2.png'
import BannerBy from './img/by/BannerBy.png'
import LogosBlockBy1440 from './img/by/LogosBlockBy1440.png'
import LogosBlockBy767 from './img/by/LogosBlockBy767.png'
import LogosBlockBy345 from './img/by/LogosBlockBy345.png'
import TypographyBlockBy1440 from './img/by/TypographyBlockBy1440.png'
import TypographyBlockBy767 from './img/by/TypographyBlockBy767.png'
import TypographyBlockBy345 from './img/by/TypographyBlockBy345.png'
import MacbookScreenBy from './img/by/MacbookScreenBy.png'
import ScreenHomepageMobileBy from './img/by/ScreenHomepageMobileBy.png'
import ScreenHomepageBy from './img/by/ScreenHomepageBy.png'
import ConclusionBy from './img/by/ConclusionBy.png'
import BannerAeco from './img/aeco/BannerAeco.png'
import LogoDesignAeco1440 from './img/aeco/LogoDesignAeco1440.png'
import LogoDesignAeco767 from './img/aeco/LogoDesignAeco767.png'
import LogoDesignAeco345 from './img/aeco/LogoDesignAeco345.png'
import HelmetAeco from './img/aeco/HelmetAeco.png'
import LogoBlockAeco from './img/aeco/LogoBlockAeco.png'
import MacbookAeco from './img/aeco/MacbookAeco.png'
import HomePageScreenAeco from './img/aeco/HomePageScreenAeco.png'
import HomePageMobileScreenAeco from './img/aeco/HomePageMobileScreenAeco.png'
import ConclusionAeco from './img/aeco/ConclusionAeco.png'
import StrategyHeaderImg from './img/StrategyHeaderImg.png'
import PoloTheBrief from './img/polo/PoloTheBrief.png'
import ConclusionPolo from './img/polo/ConclusionPolo.png'
import PoloMobileScreen1 from './img/polo/PoloMobileScreen1.png'
import PoloMobileScreen2 from './img/polo/PoloMobileScreen2.png'
import PoloMobileScreen3 from './img/polo/PoloMobileScreen3.png'
import PoloWorkflow from './img/polo/PoloWorkflow.png'
import PoloBannerImg from './img/polo/PoloBannerImg.png'

import MaratDefault from './img/teammembers/Marat-Default.png'
import MaratHover from './img/teammembers/Marat-Hover.png'
import DavitDefault from './img/teammembers/Davit-Default.png'
import DavitHover from './img/teammembers/Davit-Hover.png'
import PerchDefault from './img/teammembers/Perch-Default.png'
import PerchHover from './img/teammembers/Perch-Hover.png'
import MherDefault from './img/teammembers/Mher-Default.png'
import MherHover from './img/teammembers/Mher-Hover.png'
import ShushanDefault from './img/teammembers/Shushan-Default.png'
import ShushanHover from './img/teammembers/Shushan-Hover.png'
import VazgenDefault from './img/teammembers/Vazgen-Default.png'
import VazgenHover from './img/teammembers/Vazgen-Hover.png'
import KnarikDefault from './img/teammembers/Knarik-Default.png'
import KnarikHover from './img/teammembers/Knarik-Hover.png'
import AniDefault from './img/teammembers/Ani-Default.png'
import AniHover from './img/teammembers/Ani-Hover.png'
import GarikDefault from './img/teammembers/Garik-Default.png'
import GarikHover from './img/teammembers/Garik-Hover.png'
import ArturDefault from './img/teammembers/Artur-Default.png'
import ArturHover from './img/teammembers/Artur-Hover.png'
import MariDefault from './img/teammembers/Mari-Default.png'
import MariHover from './img/teammembers/Mari-Hover.png'
import PetrDefault from './img/teammembers/Petr-Default.png'
import PetrHover from './img/teammembers/Petr-Hover.png'





export const Svg = {
    Twitter2Svg,
    LinkedinSvg,
    FacebookSvg,
    InstagramSvg,
    HomeBannerSvg,
    LogoSvg,
    SendSvg,
    SquareScg,
    WebProgramming,
    Brain,
    Curve,
    Logistics,
    Entertainment,
    Food,
    Healthcare,
    Retail,
    Insurance,
    QuoteSvg,
    FooterLogo,
    BurgerMenu,
    CheckSvg,
    ArrowSvg,
    AttachFileSvg,
    FleetForestLogo,
    FleetForestLogoGray,
    FontBold,
    FontLight,
    StarfishProblemIcon,
    StarfishSolutionIcon,
    ChallengesStarfishSvg,
    MenuMangoLogo,
    MenuMangoLogo2,
    TherapyLakeLogo,
    LogoBy,
    BannerAeco,
    AecoLogo,
    CloseSvg,
    PoloLogo,
    ReactjsSvg,
    NodeJsSvg,
    SwiftSvg,
    NginxSvg,
    NextJsSvg,
    WordpressSvg,
    KotlinSvg,
    MongoDBSvg,
    RedisSvg,
    DynamoDBSvg,
    PostgreSQLSvg,
    MySQLSvg,
    Elasticsearch,
    AWSSvg,
    AzureSvg,
    GoogleSvg,
    NvidiaSvg,
    TransformSvg,
    PyTorchSvg,
    ChatGPTSvg,
    GoogleCloudSvg,
    SiriSvg,
    DockerSvg,
    KubernetesSvg,
    GitSvg,
    FDPSvg,
    FMSvg,
    TTSvg,
    FTSvg,
    TimeManagementSvg,
    LocationSvg,
    IterationSvg
}

export const Images = {
    MaratDefault,
    MaratHover,
    DavitDefault,
    DavitHover,
    PerchDefault,
    PerchHover,
    MherDefault,
    MherHover,
    ShushanDefault,
    ShushanHover,
    VazgenDefault,
    VazgenHover,
    KnarikDefault,
    KnarikHover,
    AniDefault,
    AniHover,
    GarikDefault,
    GarikHover,
    ArturDefault,
    ArturHover,
    MariDefault,
    MariHover,
    PetrDefault,
    PetrHover,
    HomeTab1,
    BusinessPhoto1,
    BusinessPhoto2,
    Armat,
    Fleetforest,
    Menumango,
    TheraphyLake,
    Starfish,
    Aeco,
    Polo,
    ByConsulting,
    ClientPhoto,
    aboutAs,
    Member,
    MainServices,
    SoftDev,
    MobileAppSvg,
    IntegrationsSvg,
    AutSoftSvg,
    BlogImage,
    theBrief,
    RoadMap,
    UserMark,
    UserAlex,
    UserDarlence,
    UserEmma,
    FleetForestBanner,
    LoadManagement,
    FleetUiDesign,
    LogoDesignAndBrandingFleet1440,
    LogoDesignAndBrandingFleet767,
    LogoDesignAndBrandingFleet344,
    UiUxStarfish1440,
    UiUxStarfish767,
    AiApsSvg,
    UiUxStarfish375,
    FleetTablet1,
    FleetTablet2,
    FleetTablet3,
    FleetMobile1,
    FleetMobile2,
    FleetMobile3,
    ApplicationForDriversImage,
    FleetConclusionImage,
    AboutPlatform,
    StarfishBanner,
    WorkflowStarfish,
    StarfishScreen1,
    StarfishScreen2,
    StarfishScreen3,
    StarfishBackground,
    StarfishConclusion,
    BannerMenuMango1440,
    BannerMenuMango767,
    BannerMenuMango375,
    LogoDesignAndBrandingMenuMango1440,
    LogoDesignAndBrandingMenuMango767,
    LogoDesignAndBrandingMenuMango375,
    TheBriefMenuMango,
    BriefProblemBackMenuMango,
    TypographyMenuMango,
    UserPersonasBackMenuMango,
    MenuMangoMacbook,
    MenuMangoScreens1440,
    MenuMangoScreens778,
    MenuMangoScreens375,
    ConclusionMenuMango,
    MenuMangoProjectProcess,
    TherapyLakeBanner,
    TherapyLakeBanner375,
    TherapyLakeWorkflow,
    TheBriefTherapyLake,
    LogoAndBrandingTherapyLake1440,
    LogoAndBrandingTherapyLake767,
    LogoAndBrandingTherapyLake375,
    MacbookProTherapyLake,
    TherapyLakeScreen,
    TherapyLakeMobileScreen1,
    TherapyLakeMobileScreen2,
    TherapyLakeMobileScreen3,
    ConclusionTherapyLake,
    BackgroundTherapyLake1,
    BackgroundTherapyLake2,
    BannerBy,
    LogosBlockBy1440,
    LogosBlockBy767,
    LogosBlockBy345,
    TypographyBlockBy1440,
    TypographyBlockBy767,
    TypographyBlockBy345,
    MacbookScreenBy,
    ScreenHomepageMobileBy,
    ScreenHomepageBy,
    ConclusionBy,
    LogoDesignAeco1440,
    LogoDesignAeco767,
    LogoDesignAeco345,
    HelmetAeco,
    LogoBlockAeco,
    MacbookAeco,
    HomePageScreenAeco,
    HomePageMobileScreenAeco,
    ConclusionAeco,
    StrategyHeaderImg,
    PoloTheBrief,
    ConclusionPolo,
    PoloMobileScreen1,
    PoloMobileScreen2,
    PoloMobileScreen3,
    PoloWorkflow,
    PoloBannerImg
}

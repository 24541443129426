import React from 'react';
import {InlineWidget} from "react-calendly";
import {Loader} from "components";

export const MyCalendlyComponent = () => {

    return (
        <div className='calendly-wrapper'>
            <InlineWidget
                url="https://calendly.com/eachbase/software-development"
                styles={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    height: '700px',
                    width: '100%',
                    position: 'relative',
                }}
                LoadingSpinner={() => <div style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: '100%',
                    height: '100%',
                }}><Loader styles={{width: 150, height: 150, color: '#347AF0', margin: 'auto'}}/></div>}
                pageSettings={{
                    backgroundColor: '#F8F8F8',
                    hideEventTypeDetails: true,
                    hideProfilePageViewed: true,
                    hideGdprBanner: true,
                    primaryColor: '#347AF0',
                    textColor: '#2A2F35'
                }}
            />
        </div>
    );
};


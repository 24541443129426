import React from 'react';
import {motion} from 'framer-motion'
import {Svg} from "assets/images";

export const BannerAeco = () => {
    const animationVariant = {
        visible: {
            y: 0,
            opacity: 1
        },

        hidden: {
            y: 50,
            opacity: 1
        }
    }

    return (
        <div className="aeco-banner-wrapper">
            <Svg.AecoLogo className="aeco-banner-logo"/>
            <div className='overflow-hidden'>
                <motion.div
                    className="aeco-banner-title-description"
                    initial="hidden"
                    whileInView='visible'
                    variants={animationVariant}
                    viewport={{amount: .2}}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                        repeatDelay: 1
                    }}
                >
                    <h2 className="aeco-banner-title">
                        Expert Electricians ofLos Angeles County
                    </h2>
                    <p className="aeco-banner-description">
                        We handle the most challenging electrical installation.
                    </p>
                </motion.div>
            </div>
        </div>
    );
};


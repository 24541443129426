import React from 'react';
import {Images} from "assets/images";

export const UiDesignBy = () => {
    return (
        <div className="ui-design-by-wrapper">
            <div className="container">
                <div className="ui-design-by-box">
                    <h2 className="ui-design-by-title">
                        UI Design
                    </h2>
                    <div className="macBook-screen-by">
                        <img src={Images.MacbookScreenBy} alt="macBook"/>
                    </div>

                    <div className="screens-block-by">
                        <div className="screen-by-1">
                            <img src={Images.ScreenHomepageBy} alt="screen"/>
                        </div>

                        <div className="screen-by-2">
                            <img src={Images.ScreenHomepageMobileBy} alt="screen"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


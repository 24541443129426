import React from 'react';
import {motion} from "framer-motion";
import {AnimationAndText, Card} from "components";
import {principlesCard} from "./constants";

export const PrinciplesOfWork = () => {
    const animationVariant = {
        visible: {
            y: 0,
            opacity: 1
        },

        hidden: {
            y: 100,
            opacity: 0
        }
    }

    return (
        <div className="container">
            <div className='principles-of-work-wrapper'>
                <motion.div
                    className="principles-of-work-block"
                    initial="hidden"
                    whileInView='visible'
                    variants={animationVariant}
                    viewport={{amount: .2, once: true}}
                    transition={{
                        duration: .9,
                        ease: "easeInOut",
                        repeatDelay: 1
                    }}
                >
                    <div className={'overflow-hidden'} style={{width: '100%'}}>

                        <AnimationAndText
                            text='Foundations of Success'
                            className='principles-animation-and-text'
                        />

                        <p className='principles-description'>
                            Our philosophy centers on building enduring partnerships. At Eachbase, your success is our
                            success. We dedicate ourselves to fostering strong, long-term relationships, believing that
                            mutual growth is the foundation of our collaboration.
                        </p>
                    </div>
                </motion.div>

                <div className="principles-cards">
                    {principlesCard.map(({title, text, backgroundColor, color, lineColor}, i) => (
                        <Card
                            key={i}
                            title={title}
                            text={text}
                            backgroundColor={backgroundColor}
                            color={color}
                            lineColor={lineColor}
                            className='principles-single-card'
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};


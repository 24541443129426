import React from 'react';
import {useNavigate} from "react-router";
import {NavLink} from "react-router-dom";
import {services, company} from './constants'
import {Svg} from "assets/images";

export const FooterFragment = ({footerRef}) => {
    const navigate = useNavigate()

    const goToPage = (path) => {
        navigate(path)
    }
    return (
        <footer className='footer-wrapper' ref={footerRef}>

            <div className="container">
                <div className="main-box">
                    <div className="logo-mail-social">
                        <div className="logo">
                            <Svg.LogoSvg/>
                        </div>

                        <div>
                            <p className='maile'>
                                <NavLink to='mailto:info@eachbase.com' className='link'>
                                    info@eachbase.com
                                </NavLink>
                            </p>

                            <p className='based-in'>
                                Based in Los Angeles, CA
                            </p>
                        </div>


                        <div className="social-footer">
                            <a className='linkedin' href='' target='_blank'>
                                <Svg.Twitter2Svg/>
                            </a>

                            <a className='facebook' href='https://www.linkedin.com/company/eachbase/' target='_blank'>
                                <Svg.LinkedinSvg/>
                            </a>

                            <a className='twitter' href='https://www.facebook.com/eachbase/' target='_blank'>
                                <Svg.FacebookSvg/>
                            </a>
                            <a className='twitter' href='https://www.instagram.com/eachbase/' target='_blank'>
                                <Svg.InstagramSvg/>
                            </a>
                        </div>
                    </div>

                    <div className="services-company-wrapper">
                        <div className="services-wrapper">
                            <ul className='services-block'>
                                <li>
                                    <h4 className="title">
                                        Services
                                    </h4>
                                </li>
                                {services.map((service, i) => (
                                    <li className='services-list' key={i}>
                                        <NavLink
                                            to={service.href}
                                            className='link'>
                                            {service.name}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>

                        </div>


                        <div className="company-wrapper">
                            <ul className='company-block'>
                                <li>
                                    <h4 className="title">
                                        Company
                                    </h4>
                                </li>
                                {company.map((company, i) => (
                                    <li className='company-list' key={i}>
                                        <NavLink
                                            to={company.href}
                                            className='link'>
                                            {company.name}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>

                        </div>
                    </div>
                </div>

                <div className="privacy-policy-wrapper">
                    <div className="privacy-policy-block">
                        <p className='privacy-policy'
                           onClick={() => goToPage('privacy')}
                        >
                            Privacy Policy
                        </p>

                        <p className='terms'
                           onClick={() => goToPage('terms')}
                        >
                            Terms
                        </p>
                    </div>

                    <p className="reserved">
                        2023 ©Eachbase. All rights reserved.
                    </p>

                </div>

            </div>


        </footer>
    );
};


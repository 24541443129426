import React from 'react';
import {Images} from "assets/images";
import {useWindowSize} from "utils/hooks";

export const LogoDesignBy = () => {
    const [width] = useWindowSize()

    const renderImage = (path) => {
        if (path === "logo") {
            if (width > 1279) {
                return Images.LogosBlockBy1440
            } else if (width > 767) {
                return Images.LogosBlockBy767
            } else {
                return Images.LogosBlockBy345
            }
        } else {
            if (width > 1279) {
                return Images.TypographyBlockBy1440
            } else if (width > 767) {
                return Images.TypographyBlockBy767
            } else {
                return Images.TypographyBlockBy345
            }
        }

    }

    const imageSize = (path) => {
        if (path === "logo") {
            if (width > 1279) {
                return {
                    maxWidth: 1440,
                    height: 333
                }
            } else if (width > 767) {
                return {
                    maxWidth: 768,
                    height: 333
                }
            } else {
                return {
                    maxWidth: 373,
                    height: 333
                }
            }
        } else {
            if (width > 1279) {
                return {
                    maxWidth: 1214,
                    width: "100%",
                    height: 433
                }
            } else if (width > 767) {
                return {
                    maxWidth: 688,
                    height: 732
                }
            } else {
                return {
                    maxWidth: 343,
                    height: 732
                }
            }
        }
    }

    return (
        <div className='logo-design-wrapper-by'>
            <div className="logo-design-box-by">
                <h3 className='logo-design-title-by'>
                    Logo Design
                </h3>

                <div className="logo-design-logos-block-wrapper-by">
                    <div className="logo-design-logos-block-by" style={{...imageSize("logo")}}>
                        <img src={renderImage('logo')} alt="logos"/>
                    </div>
                </div>

                <div className="container">
                    <div className="logo-design-typography-by" style={{...imageSize()}}>
                        <img src={renderImage()} alt="typography"/>
                    </div>
                </div>

            </div>
        </div>
    );
};


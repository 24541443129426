import React from 'react';
import {AnimationAndText, Card} from "components";
import {strengthsCard} from "./constants";

export const Strengths = () => {
    return (
        <div className='strengths-wrapper'>
            <div className="container">
                <AnimationAndText
                    text='Our Approach'
                    className='strengths-animation-and-text'
                />

                <div className="strengths-cards">
                    {strengthsCard.map(({title, text},i) => (
                        <Card
                            index={i}
                            key={i}
                            className="strengths-single-card"
                            title={title}
                            text={text}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};


import {Svg, Images} from "../../../assets/images";


export const integrities = [
    {
        title: 'Integrity',
        text: 'We are a team of researchers, advisors, and  communicators working together to shape the systems that shape our lives.',
        lineColor: '#2A2F35'
    },
    {
        title: 'Integrity',
        text: 'We are a team of researchers, advisors, and  communicators working together to shape the systems that shape our lives.',
        color: '#FFF',
        backgroundColor: '#FE414D',
        lineColor: '#FFF'
    },
    {
        title: 'Integrity',
        text: 'We are a team of researchers, advisors, and  communicators working together to shape the systems that shape our lives.',
        color: '#FFF',
        backgroundColor: '#FE414D',
        lineColor: '#FFF'
    },
    {
        title: 'Integrity',
        text: 'We are a team of researchers, advisors, and  communicators working together to shape the systems that shape our lives.',
        lineColor: '#2A2F35'
    },
]

export const features = [
    {
        title: 'Web Apps',
        Icon: Svg.WebProgramming,
        img: Images.HomeTab1,
        cardInfo: {
            title: 'Reactive, Real-Time, and Complex Web Applications',
            text: 'We specialize in developing web applications that are not only visually appealing but also highly' +
                ' functional. Our expertise spans ERPs, CRMs, databases, and cloud migration, ensuring your web ' +
                'solutions are robust, scalable, and capable of handling complex operations seamlessly.',
            color: '',
            lineWidth: 136,
            lineColor: '#2A2F35'
        },
        fields: [
            {
                title: 'SaaS',
                backColor: 'rgba(254, 65, 77, 0.10)',
                width: 65,
            },
            {
                title: 'ERPs',
                backColor: 'rgba(255, 160, 0, 0.10)',
                width: 56,
            },
            {
                title: 'Management Systems',
                backColor: 'rgba(254, 65, 77, 0.10)',
                width: 201,
            },
            {
                title: 'Cloud',
                backColor: 'rgba(29, 126, 255, 0.10)',
                width: 68,
            },
            {
                title: 'Analytics',
                backColor: 'rgba(43, 182, 84, 0.10)',
                width: 93,
            },
            {
                title: 'CRMs',
                backColor: 'rgba(29, 126, 255, 0.10)',
                width: 65,
            },

            {
                title: 'Databases',
                backColor: 'rgba(254, 217, 4, 0.15)',
                width: 108,
            },

        ]
    },

    {
        title: 'AI Apps',
        Icon: Svg.Brain,
        img: Images.HomeTab1,
        cardInfo: {
            title: 'Intelligent AI Solutions for Next-Generation Businesses',
            text: 'Transform your operations with our AI-powered applications. From sophisticated chatbots' +
                ' enhancing customer interactions to custom AI workflows that streamline your business processes,' +
                ' our solutions leverage cutting-edge AI technology to automate and optimize with precision.',
            color: '',
            lineWidth: 136,
            lineColor: '#2A2F35'
        },
        fields: [
            {
                title: 'AI Automation',
                backColor: 'rgba(254, 65, 77, 0.10)',
            },
            {
                title: 'Chatbots',
                backColor: 'rgba(255, 160, 0, 0.10)',
            },
            {
                title: 'AI Workflows',
                backColor: 'rgba(254, 65, 77, 0.10)',
            },
            {
                title: 'Smart Systems',
                backColor: 'rgba(29, 126, 255, 0.10)',
            },
            {
                title: 'Reasoning Engines',
                backColor: 'rgba(43, 182, 84, 0.10)',
            },

        ]
    },
    {
        title: 'Mobile Apps',
        Icon: Svg.Curve,
        img: Images.HomeTab1,
        cardInfo: {
            title: 'Engaging Mobile Applications Across All Platforms',
            text: "Expand your digital footprint with our custom mobile app development services. Whether it's for" +
                " IOS, Android, or cross-platform compatibility, we build mobile applications that deliver compelling " +
                "user experiences, optimized functionality, and seamless integration with existing systems.",
            color: '',
            lineWidth: 136,
            lineColor: '#2A2F35'
        },
        fields: [
            {
                title: 'IOS',
                backColor: 'rgba(254, 65, 77, 0.10)',
            },
            {
                title: 'Android',
                backColor: 'rgba(255, 160, 0, 0.10)',
            },
            {
                title: 'Cross Platform',
                backColor: 'rgba(254, 65, 77, 0.10)',
            }
        ]
    },

    {
        title: 'Integrations',
        Icon: Svg.Curve,
        img: Images.HomeTab1,
        cardInfo: {
            title: 'Seamless System Integrations for Enhanced Operational Efficiency',
            text: 'Elevate your business capabilities via custom integrations. Enabling seamless communication' +
                ' between your existing platforms and a wide array of external applications empowers your business ' +
                'with enhanced functionality and streamlined data flows. Our integration services are designed to ' +
                'unify your technological ecosystem, fostering efficiency, improving data accuracy, and providing' +
                ' a cohesive user experience',
            color: '',
            lineWidth: 136,
            lineColor: '#2A2F35'
        },

        fields: [
            {
                title: 'Banking Systems',
                backColor: 'rgba(254, 65, 77, 0.10)',
            },
            {
                title: 'Maps',
                backColor: 'rgba(255, 160, 0, 0.10)',
            },
            {
                title: 'Third Party Software',
                backColor: 'rgba(254, 65, 77, 0.10)',
            },
            {
                title: 'E-Commerce',
                backColor: 'rgba(29, 126, 255, 0.10)',
            },

        ]
    },
    {
        title: 'Automation Software',
        Icon: Svg.Curve,
        img: Images.HomeTab1,
        cardInfo: {
            title: 'Streamline Your Business with Advanced Automation Software',
            text: 'Revolutionize your business processes with our automation software solutions. From enhancing ' +
                'supply chain and logistics efficiency to simplifying workflows and eliminating tedious data entry' +
                ' tasks, our automation tools are designed to boost productivity, accuracy, and reliability across' +
                ' all your operations.',
            color: '',
            lineWidth: 136,
            lineColor: '#2A2F35'
        },
        fields: [
            {
                title: 'Supply Chain Automation',
                backColor: 'rgba(254, 65, 77, 0.10)',
            },
            {
                title: 'Logistics Automation',
                backColor: 'rgba(255, 160, 0, 0.10)',
            },
            {
                title: ' Workflow Automation',
                backColor: 'rgba(254, 65, 77, 0.10)',
            },
            {
                title: 'Data Entry Automation',
                backColor: 'rgba(29, 126, 255, 0.10)',
            },

        ]
    },

]


export const clientsSey = [
    {
        Icon: Svg.QuoteSvg,
        text: 'EACHBASE’s efforts have yielded a 250 increase in daily users, which the internal team expects to grow ' +
            'as they add more content. Their team is responsive, humble, and understanding. Above all, they impress by' +
            ' using all available resources for the project.',
        userInfo: {
            name: 'From Discount Poolmart',
            img: Svg.FDPSvg,
        }
    },
    {
        Icon: Svg.QuoteSvg,
        text: '"We cannot overstate the value EachBase has added to MenuMango. Their expertise, commitment,' +
            ' and quality of work have made them not just a service provider, but a true partner in our journey.' +
            ' We look forward to continuing this partnership and exploring new horizons together.',
        userInfo: {
            name: 'From Menumango',
            img: Svg.FMSvg,
        }
    },
    {
        Icon: Svg.QuoteSvg,
        text: "Honestly, it felt like they knew exactly what we needed before we even did! The software isn't" +
            " just a tool; it's a game-changer for our day-to-day operations, cutting down the clutter and" +
            " letting us focus on the drive. The EachBase team was all about making things work for us," +
            " always ready to tweak and improve. Big shoutout to them for being more than just developers",
        userInfo: {
            name: 'Fleetforest Team',
            img: Svg.FTSvg,
        }
    },
    {
        Icon: Svg.QuoteSvg,
        text: "We had this dream: a practice management software that really gets ABA therapy, something " +
            "that's not just another tool but a part of the team. EachBase made that dream a reality." +
            " They were with us every step of the way, from the first sketch of an idea to the final touches" +
            " that made it so uniquely ours. The result? A platform that's not just efficient and user-friendly," +
            " but truly understands the heart of what we do.",
        userInfo: {
            name: 'TherapyLake Team',
            img: Svg.TTSvg,
        }
    },
]
import React from 'react';
import {
    BannerMenuMango,
    ChallengesForProjects,
    InfoAboutProjects,
    LogoDesignAndBrandingMenuMango,
    ProblemAndSolutionCards, ProjectProcess, UiDesignMenuMango, UserPersonas
} from "../fragments";
import {Images} from "assets/images";
import {AboutSomething} from "fragments";
import {challengesCardsMenuMango, menuMangoProblemAndSolutionCards} from "../fragments/menuMango/constants";
import {userPersonasCardMenuMango} from "../fragments/constants";
import {StrategyCreativity} from "components";

export const MenuMango = () => {
    return (
        <div className='menu-mango-page'>
            <BannerMenuMango/>
            <InfoAboutProjects
                className='menu-mango-info-about-projects'
                client={["Menumango"]}
                industry={["Restaurants, Bars and Cafes"]}
                deliverables={["Web, iOS and Android Apps", "Branding, Architecture And UI/UX Design",
                    "Subscription based SaaS Model Development", "Functional and Non-Functional Requirements"
                ]}
            />

            <div className="menu-mango-the-brief-andProblem-solution-wrapper">
                <AboutSomething
                    className='about-something-menu-mango-wrapper  menu-mango-the-brief'
                    title="The Brief"
                    description="Menumango revolutionizes the dining experience by offering a digital menu management
                     platform for restaurants, bars, and cafes. Establishments can sign up to craft their digital
                      menus, create items, tables, and generate unique QR codes for each table or the overall menu.
                       Customers enjoy the convenience of scanning a QR code to view the menu, order directly from
                       their screens, while staff can efficiently manage and fulfill orders, enhancing service speed
                       and customer satisfaction."
                    link={{name: 'Visit website', href: 'https://www.menumango.com/'}}
                    img={Images.TheBriefMenuMango}
                />

                <ProblemAndSolutionCards
                    className='problem-and-solution-MenuMango'
                    cards={menuMangoProblemAndSolutionCards}
                />
            </div>

            <ProjectProcess/>

            <ChallengesForProjects
                classNameWrapper="challenges-menu-mango"
                cards={challengesCardsMenuMango}
                challengesForProjectsTitle="Challenges"
            />

            <LogoDesignAndBrandingMenuMango/>

            <UserPersonas
                className='user-persons-menu-mango'
                userPersonasCard={userPersonasCardMenuMango}
            />

            <UiDesignMenuMango/>

            <AboutSomething
                className='about-something-menu-mango-wrapper  menu-mango-conclusion'
                title="Conclusion"
                description="Menumango serves as a transformative tool for the food service industry, redefining how
                restaurants manage and present their menus. This innovative platform not only streamlines the ordering
                process but also boosts staff productivity by simplifying table and order management. With Menumango,
                 restaurants can offer a seamless and modern dining experience, paving the way for enhanced customer
                  satisfaction and operational excellence.
"
                img={Images.ConclusionMenuMango}
            />
            <StrategyCreativity/>
        </div>
    );
};


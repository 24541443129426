import {Svg} from "assets/images";

export const menuMangoProblemAndSolutionCards = [
    {
        Icon: Svg.StarfishProblemIcon,
        title: 'Problem',
        text: "Restaurants, bars, and cafes face challenges in menu management, customer ordering efficiency, and order" +
            " fulfillment. Traditional methods lead to mix-ups, slower service, and a less interactive dining " +
            "experience. In addition, in our post-COVID world, many users prefer to use more sanitary, Eco-friendly " +
            "technologies that will enable them to see product images, caloric information and share the menu items" +
            " with their friends and family."
    },
    {
        Icon: Svg.StarfishSolutionIcon,
        title: "Solution",
        text:"Menumango addresses these issues by providing a digital menu management platform where venues can" +
            " create and manage their menus online. Customers can order directly through the app after scanning " +
            "QR codes, improving order accuracy and staff efficiency. This system streamlines the dining experience," +
            " making it more enjoyable for both customers and staff."
    }
]

export const challengesCardsMenuMango = [
    {
        className:'challenges-menu-mango-single-card',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Menu Management",
        text:"Restaurants struggle to keep physical menus updated, leading to customer confusion and dissatisfaction."
    },
    {
        className:'challenges-menu-mango-single-card',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Order Efficiency",
        text:"Traditional ordering methods are slow and prone to errors, affecting the dining experience and " +
            "operational efficiency."
    },
    {
        className:'challenges-menu-mango-single-card',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Engaging  Tech-Friendly Customers",
        text:"Identifying the correct table for order delivery in a busy setting can be challenging, risking service" +
            " quality and customer satisfaction."
    },

]

import React, {useEffect, useRef, useState} from 'react';
import {Outlet, useLocation, useNavigate} from "react-router";
import {useWindowSize} from "utils/hooks";
import {headerItems} from "./constants";
import {IconButton} from "@mui/material";
import {Svg} from "assets/images";
import {MainMenu} from "../menus";
import {FooterFragment} from "../footer/fotterFragment";


export const Layout = () => {
    const [openingMenu, setOpeningMenu] = useState(false);
    const [isOpenSubOption, setOpeningSubOption] = useState([])
    const navigate = useNavigate();
    const location = useLocation()
    const [width] = useWindowSize()
    const footerRef = useRef()
    const headerRef = useRef()

    useEffect(() => {
            window.scroll(0, 0)
            window.scrollTo(0, 0);
    }, [location])



    const toggleMenu = () => {
        setOpeningMenu(!openingMenu)
    }

    const handleClickToLogo = () => {
        navigate('/')
        window.scroll(0, 0)
        setOpeningMenu(false)
    }

    const handleClickToLink = (path) => {
        if (path) navigate(path)
    }

    const renderImage = () => {
        return {
            icon: <Svg.FooterLogo/>,
            class: 'header-box background-white'
        }
    }

    return (
        <div className='layout-wrapper'>
            <header ref={headerRef} className={`header ${renderImage()?.class}`}>
                <div className="container">
                    <div className='header-wrapper'>
                        <div className='logo-block' onClick={handleClickToLogo}>
                            {renderImage()?.icon}
                        </div>

                        {width > 1279 ?
                            <ul className='list-block'>
                                {headerItems.map(({key, name, path}) => (
                                    <li key={key} className='list-item'>
                                        <p
                                            onClick={() => handleClickToLink(path)}
                                            className={`link ${location.pathname === path && 'active-link'}`}
                                        >
                                            {name}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                            :
                            <div className='menu-block'>
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-haspopup="true"
                                    onClick={() => toggleMenu(!openingMenu)}
                                >
                                    {width < 768 && openingMenu ? <Svg.CloseSvg/> : <Svg.BurgerMenu/>}

                                </IconButton>

                                <MainMenu
                                    openingMenu={openingMenu}
                                    isOpenSubOption={isOpenSubOption}
                                    setOpeningSubOption={setOpeningSubOption}
                                    toggleManu={toggleMenu}
                                />
                            </div>
                        }
                    </div>
                </div>
            </header>
            <main className='main' style={{marginTop: `${headerRef.current?.clientHeight}px`}}>
                <Outlet/>
            </main>
            <FooterFragment footerRef={footerRef}/>
        </div>

    );
};

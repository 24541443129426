import React from 'react';
import {useNavigate} from "react-router";
import {motion} from "framer-motion";
import {Button} from "components";
import {ourExperience} from "./constants";

export const OurExperience = () => {
    const navigate = useNavigate();

    const animationVariant = {
        visible: {
            y: 0,
            opacity: 1,
            visible: 'visible'
        },

        hidden: {
            y: 80,
            opacity: 0,
            visible: 'hidden'
        }
    }
    const animationVariantItems = {
        visible: {
            y: 0,
            opacity: 1,
            visible: 'visible'
        },

        hidden: {
            y: -80,
            opacity: 0,
            visible: 'hidden'
        }
    }

    return (
        <div className='ourExperience-wrapper'>
            <div className="container">
                <div className="ourExperience-box">
                    <div className='overflow-hidden'>
                        <motion.div
                            className="ourExperience-left-block"
                            initial="hidden"
                            whileInView='visible'
                            variants={animationVariant}
                            viewport={{amount: .2, once: true}}
                            transition={{
                                duration: .9,
                                ease: "easeInOut",
                                repeatDelay: 1
                            }}
                        >
                            <h3 className='ourExperience-left-block_title'>
                                <span style={{color: '#FED904'}}>More than 1 Million</span> lines of code in production.
                            </h3>

                            <p className='ourExperience-left-block_description'>
                                Our codebase in production surpasses the one-million-line mark, showcasing the
                                scale and depth of our technical capabilities.
                            </p>

                            <Button
                                color='#2A2F35'
                                background="#FED904"
                                text='Let’s Talk'
                                className='go-in-btn'
                                onClick={() => navigate('/contact-us')}
                            />
                        </motion.div>
                    </div>


                    <div className="ourExperience-right-block">
                        {ourExperience.map(({title, description, Svg}, i) => (
                            <div className='overflow-hidden' key={i}>
                                <motion.div
                                    className='ourExperience-right-block_items'
                                    initial="hidden"
                                    whileInView='visible'
                                    variants={animationVariantItems}
                                    viewport={{amount: .2, once: true}}
                                    transition={{
                                        duration: .9,
                                        ease: "easeInOut",
                                        repeatDelay: 1
                                    }}
                                >
                                    <Svg className='ourExperience-right-block_items-svg'/>

                                    <div className='ourExperience-right-block_items-title-description'>
                                        <h4 className='ourExperience-right-block_items-title'>
                                            {title}
                                        </h4>

                                        <p className='ourExperience-right-block_items-description'>
                                            {description}
                                        </p>
                                    </div>
                                </motion.div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};


import React, {useMemo, useState} from 'react';
import {OurProjects} from "fragments";
import {StrategyCreativity} from "components";
import {Images} from "assets/images";
import {useNavigate} from "react-router";

export const OurProjectsPage = () => {
    const [currentFilterProject, setCurrentFilterProject] = useState('')
    const navigate = useNavigate()

    const viewMoreHandle = (path) => {
        navigate(path)
    }

    const handleFilter = (path) => {
        setCurrentFilterProject(path)
    }

    const ourProjects = [
        {
            title: 'Menumango',
            titleColor: "#EF4940",
            description: 'Advanced Menu System for Restaurants',
            productInfo: `QR-enabled menu with a built-in ordering system, designed to be customer-centric.
            Features quick implementation and high adaptability.`,
            productImg: Images.Menumango,
            onClick: () => viewMoreHandle('menumango'),
            rowReverse: false
        },
        {
            title: 'Fleetforest',
            titleColor: "#049494",
            description: 'Powering America’s Logistics Industry',
            productInfo: 'Featuring several software systems all integrated into one highly effective ecosystem ' +
                'to enhance the operational efficiency of carrier companies in the logistics industry',
            productImg: Images.Fleetforest,
            onClick: () => viewMoreHandle('fleet-forest'),
            rowReverse: true
        },
        {
            title: 'TherapyLake',
            titleColor: "#347AF0",
            description: 'Specialized Software Streamlining ABA Practice Management',
            productInfo: 'HIPAA compliant, modern Practice Management System for ABA healthcare sector.' +
                ' Features enhanced scheduling, billing, posting, credentialing and more.',
            productImg: Images.TheraphyLake,
            onClick: () => viewMoreHandle("therapylake"),
            rowReverse: false
        },
        {
            title: 'Starfish',
            titleColor: "#BA43B1",
            description: 'Empower Your E-commerce Universe',
            productInfo: "Starfish is a software system that runs in the cloud and connects to Amazon, eBay," +
                " Walmart, Google and Erply to synchronize product quantities and create orders.",
            productImg: Images.Starfish,
            onClick: () => viewMoreHandle("starfish"),
            rowReverse: true
        },
        {
            title: 'AECO',
            titleColor: "#FE414D",
            description: 'Illuminating Excellence, One Connection at a Time',
            productInfo: 'AECO tailored approach has empowered them to shine brightly online, connecting with their' +
                ' audience effortlessly.',
            productImg: Images.Aeco,
            onClick: () => viewMoreHandle("aeco"),
            rowReverse: false
        },
        {
            title: 'Polo TMS',
            titleColor: "#AD8D29",
            description: 'Navigating Logistics with Precision',
            productInfo: 'Empowering Logistics Operations with a Powerful Platform Featuring Advanced Integration' +
                ' and Automations for FTL, LTL, and 3PL Services Provider.',
            productImg: Images.Polo,
            onClick: () => viewMoreHandle("polo"),
            rowReverse: true
        },
    ]

    const projectFilters = useMemo(() => [
        {
            title: 'All',
            onClick: () => handleFilter('All'),
        },
        {
            title: 'Logistic',
            onClick: () => handleFilter('Logistic'),
        },
        {
            title: 'Healthcare',
            onClick: () => handleFilter('Healthcare'),
        },
        {
            title: 'Healthcare',
            onClick: () => handleFilter('Healthcare'),
        },

    ], [])

    return (
        <div className='our-projects-page'>
            <OurProjects
                ourProjects={ourProjects}
                projectFilters={projectFilters}
                currentFilterProject={currentFilterProject}
            />

            <StrategyCreativity/>
        </div>
    );
};


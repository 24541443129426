import React, {useRef, useState} from 'react';
import {features} from "./constants";
import {Card, ScrollWrapper} from "components";


export const Services = () => {
    const [currentValue, setCurrentValue] = useState(features[0].title)
    const sectionsRef = useRef([]);
    const wrapperRef = useRef(null);

    const handleScrollTo = (index) => {
        if (sectionsRef.current[index]) {
            sectionsRef.current[index].scrollIntoView({behavior: 'smooth', block: 'nearest'});
        }
    };

    const setSectionRef = (el, index) => {
        sectionsRef.current[index] = el;
    };

    return (
        <div className='services-section'>
            <h2 className='services-section-title'>
                Let’s Get Started
            </h2>
            <div className='services-section-block' id="anchor-name">
                <div className='services-section-steps'>
                    {features.map(({title}, index) => (
                        <li key={index}
                            className={`services-section-steps-title ${currentValue === title ? 'active-title' : ''}`}
                            onClick={() => handleScrollTo(index)}
                        >
                        <span className='services-section-steps-score'>
                            -
                        </span>
                            {title}
                        </li>
                    ))}
                </div>

                <div className="features-block">
                    {features.map((future, index) => (
                        <div
                            key={index}
                            ref={(el) => setSectionRef(el, index)}
                        >
                            <ScrollWrapper
                                wrapperRef={wrapperRef}
                                title={future.title}
                                onChange={(isInView) => isInView && setCurrentValue(() => future.title)}
                            >
                                <div className='items-wrapper' id={future.title}>
                                    {future?.fields?.map((filed) => (
                                        <p
                                            className='single-item'
                                            key={filed.title}
                                            style={{background: filed.backColor}}
                                        >
                                            {filed.title}
                                        </p>
                                    ))}
                                </div>

                                <Card
                                    index={index}
                                    className='digital-first'
                                    text={future?.cardInfo?.text}
                                    title={future?.cardInfo?.title}
                                    color={future?.cardInfo?.color}
                                />
                            </ScrollWrapper>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};


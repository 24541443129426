import React from 'react';
import {NavLink} from "react-router-dom";

export const TheBriefAeco = () => {
    return (
        <div className="the-brief-wrapper-aeco">
            <div className="container">
                <div className="the-brief-box-aeco">
                    <h3 className="the-brief-aeco-title">
                        The Brief
                    </h3>

                    <p className="the-brief-aeco-description">
                        TherapyLake is management system to help healthcare practices manage their business, streamline
                        operations, provide higher quality patient care and improve revenue collection. We are
                        committed to simplifying management by providing our users with a suite of tools and services
                        that enhance efficiency and optimize workflows.
                    </p>

                    <NavLink
                        to="./"
                        className="the-brief-aeco-link"
                    >
                        Visit website
                    </NavLink>
                </div>
            </div>
        </div>
    );
};


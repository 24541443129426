import React from 'react';
import {Card} from "components";


export const ProblemAndSolutionCards = ({cards, className}) => {

    return (
        <div className="container">
            <div className={`problem-and-solution-cards-box ${className ? className : ''}`}>
                {cards.map(({Icon, title, text}, i) => (
                    <Card
                        key={i}
                        index={i}
                        className='problem-and-solution-single-card'
                        Icon={Icon}
                        title={title}
                        text={text}
                    />
                ))}
            </div>
        </div>
    );
};


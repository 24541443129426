import {Images} from "assets/images";



export const keyFeatures = [
    {
        title: 'Manage Loads',
    },
    {
        title: 'Drivers’ App (IOS, Android)',
        className: 'margin-right',
    },
    {
        title: 'Manage Trucks',
    },
    {
        title: 'Calculate profit',
        className: ' margin-right',
    },
    {
        title: 'Show data analytic',
    },
    {
        title: 'Optimize trips',
        className: 'margin-right',
    },
    {
        title: 'Document Management',
        className: 'margin-bottom',
    },
    {
        title: 'Maintenance each vehicle',
        className: 'margin-bottom margin-right max-width-1279-margin-bottom',
    },
]

export const challengesCard = [
    {
        title: 'Advanced Load Planning',
        text: 'Tackling the complexity of planning loads in advance,' +
            ' ensuring drivers can optimize their routes efficiently for' +
            ' time and cost savings.'
    },
    {
        title: 'Streamlining Financials',
        text: 'Addressing the challenge of accurately calculating operational costs and profits, making ' +
            'financial management straightforward and reliable for carriers.'
    },
    {
        title: 'Putting It All Together',
        text: "Overcoming the hurdle of tracking and managing critical documents, loads, and receivables to maintain" +
            " smooth operational flow."
    },
]



export const fleetTabletScreens = [
    {
        img:Images.FleetTablet1,
        className:'fleet-tablet-1'
    },
    {
        img:Images.FleetTablet2,
        className:'fleet-tablet-2'
    },
    {
        img:Images.FleetTablet3,
        className:'fleet-tablet-3'
    },
]
export const fleetMobileScreens = [
    {
        img:Images.FleetMobile1,
        className:'fleet-mobile-1'
    },
    {
        img:Images.FleetMobile2,
        className:'fleet-mobile-2'
    },
    {
        img:Images.FleetMobile3,
        className:'fleet-mobile-3'
    },
]
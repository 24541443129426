import React from 'react';
import {motion} from 'framer-motion'
import {Images} from "assets/images";

export const ScreensStarfish = () => {
    const animVariantTitle = {
        visible: {
            y: 0,
            opacity: 1
        },

        hidden: {
            y: 0,
            opacity: .8
        }
    }

    const animVariantImg = {
        visible: {
            scale: 1,
            opacity: 1
        },

        hidden: {
            scale: .8,
            opacity: .8
        }
    }

    return (
        <div className='starfish-screens-wrapper'>
            <div className='overflow-hidden'>
                <motion.h2
                    className='starfish-screens-title'
                    initial="hidden"
                    whileInView='visible'
                    variants={animVariantTitle}
                    viewport={{amount: .2, once: true}}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                        repeatDelay: 1
                    }}
                >
                    Screens
                </motion.h2>
            </div>

            <div className="starfish-screens-box">
                <motion.div
                    initial="hidden"
                    whileInView='visible'
                    variants={animVariantImg}
                    viewport={{amount: .2, once: true}}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                        repeatDelay: 1
                    }}
                    className="starfish-single-screens starfish-screens-1"
                >
                    <img src={Images.StarfishScreen1} alt="Starfish Screen"/>
                </motion.div>

                <motion.div
                    className="starfish-single-screens starfish-screens-2"
                    initial="hidden"
                    whileInView='visible'
                    variants={animVariantImg}
                    viewport={{amount: .2, once: true}}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                        repeatDelay: 1
                    }}
                >
                    <img src={Images.StarfishScreen2} alt="Starfish Screen"/>
                </motion.div>

                <motion.div
                    className="starfish-single-screens starfish-screens-3"
                    initial="hidden"
                    whileInView='visible'
                    variants={animVariantImg}
                    viewport={{amount: .2, once: true}}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                        repeatDelay: 1
                    }}
                >
                    <img src={Images.StarfishScreen3} alt="Starfish Screen"/>
                </motion.div>
            </div>
        </div>
    );
};


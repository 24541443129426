import React from 'react';
import {motion} from "framer-motion";
import {Svg, Images} from "assets/images";
import {useWindowSize} from "utils/hooks";

export const BannerMenuMango = () => {
    const [width] = useWindowSize()

    const renderImage = () => {
        if (width > 1279) {
            return Images.BannerMenuMango1440
        } else if (width > 767) {
            return Images.BannerMenuMango767
        } else {
            return Images.BannerMenuMango375
        }
    }

    const imageSize = () => {
        if (width > 1279) {
            return {
                width: 944,
                height: 709
            }
        } else if (width > 767) {
            return {
                maxWidth: 768,
                height: 723,
            }
        } else {
            return {
                maxWidth: 376,
                height: 376,
            }
        }
    }

    const animVariant = {
        hidden: {
            y: 60,
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
        }
    };


    return (
        <div className='menu-mango-banner'>
            <motion.div
                className="menu-mango-logo-title-description-block"
                variants={animVariant}
                initial="hidden"
                whileInView='visible'
                viewport={{amount: .2, once: true}}
                transition={{
                    duration: 1,
                    ease: "easeInOut",
                }}
            >
                <div

                >
                    <Svg.MenuMangoLogo className='menu-mango-logo'/>
                </div>

                <h1
                    className='menu-mango-banner-title'
                >
                    Build Interactive <br/>
                    QR Menus
                </h1>

                <p
                    className='menu-mango-banner-description'
                >
                    QR enabled menu with built-in ordering system designed to be a customer-centric
                    solution with quick implementation and high adaptability
                </p>
            </motion.div>

            <div className="menu-mango-banner-image" style={{...imageSize()}}>
                <img src={renderImage()} alt="banner MenuMango"/>
            </div>
        </div>
    );
};



import {Svg} from "assets/images";


export const starfishProblemAndSolutionCards = [
    {
        Icon: Svg.StarfishProblemIcon,
        title: 'Problem',
        text: "The client faced scalability and operational challenges, primarily due to inconsistent inventory " +
            "levels across sales channels. Issues arose when sold products on one platform didn't reduce" +
            " inventory counts on others, and when restocked items weren't updated online without manual" +
            " intervention, leading to missed sales. These, along with PO and order synchronization difficulties," +
            " significantly hampered efficiency."
    },
    {
        Icon: Svg.StarfishSolutionIcon,
        title: "Solution",
        text:"A comprehensive system designed to unify and automate e-commerce operations. It streamlines processes" +
            " by automatically syncing POs, inventory, and orders across all sales channels, ensuring consistency" +
            " and eliminating data entry errors through API integrations. Starfish also facilitates stock transfers " +
            "and inventory updates, enhancing efficiency. Its user-friendly interface allows for manual overrides," +
            " ensuring it complements human efforts for optimal operational workflow."
    }
]

export const challengesCardsStarfish = [
    {
        className:'challenges-starfish-single-card',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Data Synchronization",
        text:"Struggled with maintaining accurate inventory counts across various" +
            " sales channels, leading to overstock or stockouts."
    },
    {
        className:'challenges-starfish-single-card',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Platform Integration Complexity",
        text:"Faced delays in reflecting new stock on online platforms due to reliance on manual" +
            " updates, missing potential sales."
    },
    {
        className:'challenges-starfish-single-card',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Performance and Scalability",
        text:"Encountered difficulties in PO and order synchronization across different platforms," +
            " hampering operational efficiency."
    },

]

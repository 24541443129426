import React from 'react';
import {Images} from "assets/images";

export const WorkflowStarfish = () => {
    return (
        <div className='workflow-starfish-wrapper'>
            <div className="container">
                <div className="workflow-starfish-box">
                    <h2 className='workflow-starfish-title'>
                        Workflow
                    </h2>

                    <div className="workflow-starfish-img-block">
                        <img src={Images.WorkflowStarfish} alt='workflow'/>
                    </div>
                </div>
            </div>
        </div>
    );
};


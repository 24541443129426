import {Images, Svg} from "assets/images";

export const services = [
    {
        animationText: 'Let’s Start Building Together',
        id: 'lets-start',
        title: 'Your partners from concept to deployment. We specializes in turning software ideas into reality,' +
            ' covering every phase of the software development cycle so you can concentrate on your businesses.' +
            ' From AI innovations and mobile apps to systems integrations and automation, we deliver superior ' +
            'solutions tailored to your specific needs.',
        img: Images.MainServices,
        className: 'our-service-block'
    },
    {
        title: 'Web Applications',
        id: 'web-app',
        description: 'Crafting responsive, user-friendly web applications tailored to meet your unique business' +
            ' needs. Utilizing the latest technologies, such as ReactJS, MongoDB, Python,' +
            '  we ensure your web presence is powerful and compelling.',
        img: Images.SoftDev,
        className: "soft-dev-block",
        whatWeDo: ['ERPs, CRMs, TMSs,  LMSs and More',
            'Custom Solutions, Real-Time Apps',
            'SaaS, IoT, Cloud, WebSecurity',
            'Database, Microservices, PWAs'
        ]
    },
    {
        title: 'A.I. Apps',
        id: 'ai-apps',
        description: "Driving innovation with AI-powered applications, designed to enhance decision-making," +
            " automate processes, and provide insightful analytics. Let's build intelligent solutions that" +
            " propel your business into the future",
        img: Images.AiApsSvg,
        className: "ui-ux-block",
        whatWeDo: ['Decision Automation, Analytical Insights',
            'AI Fine Tuning and Deployment',
            'Interactive AI, Chatbots, Fully Integrated AI',
            'ChatGPT, Google AI, Amazon Q'
        ]
    },
    {
        title: 'Mobile Applications',
        id: 'mobile-apps',
        description: 'Developing high-performance mobile applications that offer engaging user experiences across ' +
            'iOS and Android platforms. Expand your reach and connect with users anywhere, anytime.',
        img: Images.MobileAppSvg,
        className: "business-intel-block",
        whatWeDo: ['iOS, Android, Cross-Platform Solutions', 'Biometrics, Geofencing, Wallets',
            "AR/VR, Payments, Voice, Push Notifications", "Stand-alone Apps, Integrated Apps",
            ]
    },
    {
        title: 'Integrations',
        id: 'integrations',
        description: 'Seamlessly integrating disparate systems and applications to enhance functionality,' +
            ' improve data flow, and optimize your IT infrastructure. Our integration services ensure your systems' +
            ' work together, efficiently',
        img: Images.IntegrationsSvg,
        className: "marketing-block",
        whatWeDo: ['System Harmonization', 'Enhanced Functionality', 'Data Flow Optimization']
    },
    {
        title: 'Automation Software',
        id: 'aut-soft',
        description: 'Empowering your business with automation software that streamlines workflows, reduces manual' +
            ' tasks, and increases productivity. Transform your operations with solutions that are smart and scalable.',
        img: Images.AutSoftSvg,
        className: "aut-soft-block",
        whatWeDo: ['Workflow Streamlining', 'Task Reduction', "Automated Reporting", "Background Tasks"]
    },
]

export const howWeWorkCards = [
    {
        title: 'Research Strategy',
        text: 'We emphasizes honesty, transparency, and ' +
            'ethical behavior in all aspects of the business, ' +
            'including interactions with customers, employees, and partners.',
        background: 'rgba(254, 217, 4, 0.10)',
        squareColor: '#FED904',
        rotate: '45deg'
    },
    {
        title: 'Research Strategy',
        text: 'We emphasizes honesty, transparency, and ' +
            'ethical behavior in all aspects of the business, ' +
            'including interactions with customers, employees, and partners.',
        background: 'rgba(43, 182, 84, 0.15)',
        squareColor: '#2BB654',
        rotate: '30deg'
    },
    {
        title: 'Research Strategy',
        text: 'We emphasizes honesty, transparency, and ' +
            'ethical behavior in all aspects of the business, ' +
            'including interactions with customers, employees, and partners.',
        background: 'rgba(52, 122, 240, 0.15)',
        squareColor: '#347AF0',
        rotate: '19.628deg'
    },
    {
        title: 'Research Strategy',
        text: 'We emphasizes honesty, transparency, and ' +
            'ethical behavior in all aspects of the business, ' +
            'including interactions with customers, employees, and partners.',
        background: 'rgba(254, 65, 77, 0.15)',
        squareColor: '#FE414D',
        rotate: '50.181deg'
    },
]


export const tabs = [
    {
        title: 'Web & Mobile Development',
        images: [
            {
                title: 'ReactJS',
                Svg: Svg.ReactjsSvg,
            },
            {
                title: 'NodeJs',
                Svg: Svg.NodeJsSvg,
            },
            {
                title: 'Swift (IOS)',
                Svg: Svg.SwiftSvg,
            },
            {
                title: 'NGINX',
                Svg: Svg.NginxSvg,
            },
            {
                title: 'NextJS',
                Svg: Svg.NextJsSvg,
            },
            {
                title: 'Wordpress',
                Svg: Svg.WordpressSvg,
            },
            {
                title: 'Kotlin (Android)',
                Svg: Svg.KotlinSvg,
            },
        ]
    },
    {
        title: 'Database Technologies',
        images: [
            {
                title: 'MongoDB',
                Svg: Svg.MongoDBSvg,
            },
            {
                title: 'Redis',
                Svg: Svg.RedisSvg,
            },
            {
                title: 'DynamoDB',
                Svg: Svg.DynamoDBSvg,
            },
            {
                title: 'MySQL',
                Svg: Svg.MySQLSvg,
            },
            {
                title: 'PostgreSQL',
                Svg: Svg.PostgreSQLSvg,
            },
            {
                title: 'Elasticsearch',
                Svg: Svg.Elasticsearch,
            },
        ]
    },
    {
        title: 'Cloud Platforms',
        images: [
            {
                title: 'AWS',
                Svg: Svg.AWSSvg,
            },
            {
                title: 'Azure',
                Svg: Svg.AzureSvg,
            },
            {
                title: 'Google Cloud Platform',
                Svg: Svg.GoogleSvg,
            },
            {
                title: 'NVIDIA',
                Svg: Svg.NvidiaSvg,
            },
        ]
    },
    {
        title: 'AI & Machine Learning',
        images: [
            {
                title: 'TensorFlow',
                Svg: Svg.TransformSvg,
            },
            {
                title: 'PyTorch',
                Svg: Svg.PyTorchSvg,
            },
            {
                title: 'ChatGPT',
                Svg: Svg.ChatGPTSvg,
            },
            {
                title: 'Google Cloud AI',
                Svg: Svg.GoogleCloudSvg,
            },
            {
                title: 'Siri',
                Svg: Svg.SiriSvg,
            },
        ]
    },
    {
        title: 'DevOps & Deployment',
        images: [
            {
                title: 'Docker',
                Svg: Svg.DockerSvg,
            },
            {
                title: 'Kubernetes',
                Svg: Svg.KubernetesSvg,
            },
            {
                title: 'AWS EC2, AWS CodePipeline, AWS Route 53, AWS S3tGPT',
                Svg: Svg.AWSSvg,
                className: 'aws'
            },
            {
                title: 'Git',
                Svg: Svg.GitSvg,
            },
        ]
    },
]
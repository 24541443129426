export const headerItems = [
    {
        key: '1',
        name: 'About Us',
        path: '/about-us'
    },
    {
        key: '2',
        name: 'Our Projects',
        path: '/our-projects'
    },
    {
        key: '3',
        name: 'Services',
        path: '/services'
    },
    // {
    //     key: '4',
    //     name: 'Blog',
    //     path: '/blog'
    // },
    {
        key: '5',
        name: 'Contact Us',
        path: '/contact-us'
    },

]



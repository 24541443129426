import React, {useId} from 'react';
import {motion} from "framer-motion";
import {Card} from "components";
import {Images} from "assets/images";
import {BusinessFeaturesExample} from "../fragments";

const titleVariant = {
    visible: {
        y: 0,
    },
    hidden: {
        y: 150,
    }
}

export const BetterBusinessDecision = () => {

    return (
        <div className='better-business-decision-wrapper'>
            <div className='better-business-wrapper'>
                <div className="container">
                    <div className="better-business-box">
                        <div className='overflow-hidden'>
                            <motion.h2
                                className='title'
                                variants={titleVariant}
                                initial='hidden'
                                viewport={{amount: .2, once: true}}
                                whileInView='visible'
                                transition={{
                                    duration: .9,
                                    ease: "easeInOut",
                                }}
                            >
                                Value Driven Innovation.
                            </motion.h2>
                        </div>

                        <Card
                            className='batter-business-card'
                            text={`We harness the power of data to guide our innovation. Advanced analytics enable
                             us to transform data into actionable insights, ensuring strategies and solutions are
                              efficient and grounded in evidence. This approach ensures we're always aligned with 
                              market demands and customer needs.`}
                            color='#000'
                            title='Data-Driven Decisions'
                            backgroundColor='#FED904'
                            lineColor='#000'
                        />
                    </div>
                </div>
            </div>

            <div className="examples-for-business-box">
                <div className="container">
                    <div className='examples-for-business-content'>

                        <BusinessFeaturesExample
                            title={`We Are Agile`}
                            description={`Flexibility and speed define our development process. Adopting an agile
                             methodology, we prioritize quick market releases and the ability to adapt features based
                              on evolving user feedback and market trends. This approach allows us to stay ahead of 
                              the curve, ensuring our solutions are not only innovative but also highly relevant and
                               responsive to market needs.`}
                            img={Images.BusinessPhoto1}
                        />

                        <BusinessFeaturesExample
                            title={`Transparent Pricing`}
                            description={`The quote we provide for approved requirements is fixed-no surprises or
                             additional charges, even if development takes longer than expected. Only pay upon 
                             successful delivery of the software, ensuring your investment directly correlates with
                              the value we provide.`}
                            classNameWrapper='row-reverse'
                            img={Images.BusinessPhoto2}
                        />
                    </div>
                </div>

            </div>

            <div className="background-primary"/>
        </div>
    );
};


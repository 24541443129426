import React, {useEffect} from 'react';
import {useLocation} from "react-router";
import {services} from "./fragments/constants";
import {AboutSomething} from "fragments";
import { WeUse} from "./fragments";
import {IndustryExpertise} from "fragments/industryExpertise/industryExpertise";
import {StrategyCreativity} from "components";


export const Services = () => {
    const location = useLocation()

    useEffect(() => {
        if (location?.hash) {
            const element = document.getElementById(location?.hash?.slice(1));
            if (element) {
                element.scrollIntoView({block: "end", behavior: 'smooth'});
            }
        }

    }, [location])


    return (
        <div className='services-page'>
            {services.map(({animationText, title, description, img, whatWeDo, className, id}, i) =>
                (
                    <AboutSomething
                        key={i}
                        id={id}
                        animationText={animationText}
                        title={title}
                        description={description}
                        img={img}
                        whatWeDo={whatWeDo}
                        className={className}
                    />
                )
            )}

            <WeUse/>

            <div className="container">
                <IndustryExpertise/>
            </div>
            <StrategyCreativity/>
        </div>
    );
};


import React from 'react';
import {StrategyCreativity} from "components";
import {AboutSomething} from "fragments";
import {
    BannerPolo,
    Challenges,
    InfoAboutProjects,
    MobileApplicationsPolo,
    ProblemAndSolutionCards,
    WorkflowPolo
} from "../fragments";
import {challengesCardsPolo, poloProblemAndSolutionCards} from "../fragments/polo/constants";
import {Images} from "assets/images";

export const Polo = () => {
    return (
        <div className='polo-page'>
            <BannerPolo/>

            <InfoAboutProjects
                client={["Polo TMS"]}
                industry={["Logistic and Transportation"]}
                deliverables={["UI/UX Design", "Branding and Identity", "Web Development", "IOS and Android Development"]}
            />

                <AboutSomething
                    className="the-brief-polo-wrapper"
                    title="The Brief"
                    img={Images.PoloTheBrief}
                    description="PoloTMS is a bespoke operational hub crafted for a leading logistics company
                     specializing in FTL, LTL, and 3PL services. This comprehensive system is designed to be
                      the central pillar for their operations, integrating complex permission systems, load
                      management, profit sharing, and essential automation for key business processes. Coupled
                      with a powerful mobile application and integrations, it enhances the operational
                       proficiency of PoloTMS and propels them ahead of the competition."
                    link={{name: 'Visit website', href: 'https://polotms.eachbase.com/'}}
                />


            <div className="polo-wrapper-problem-and-solution">
                <ProblemAndSolutionCards
                    className='problem-and-solution-polo'
                    cards={poloProblemAndSolutionCards}
                />
            </div>

            <Challenges
                classNameWrapper="challenges-polo-wrapper"
                challengesCard={challengesCardsPolo}
            />

            <WorkflowPolo/>

            <MobileApplicationsPolo/>

            <AboutSomething
                className='the-brief-polo-wrapper polo-conclusion'
                title="Conclusion"
                description="PoloTMS stands as a testament to innovative solutions addressing the complex challenges
                within the logistics industry. By automating and integrating key processes, it not only resolves manual
                 inefficiencies and data management issues but also ushers in a new era of operational excellence.
                  PoloTMS exemplifies how tailored technology can transform business operations, ensuring seamless,
                  efficient, and secure logistics management."
                img={Images.ConclusionPolo}
            />

            <StrategyCreativity/>
        </div>
    );
};


import React from 'react';
import {Images} from "assets/images";
import {useWindowSize} from "utils/hooks";

export const LogoDesignTherapyLake = () => {

    const [width] = useWindowSize()

    const renderImage = () => {
        if (width > 1279) {
            return Images.LogoAndBrandingTherapyLake1440
        } else if (width > 767) {
            return Images.LogoAndBrandingTherapyLake767
        } else {
            return Images.LogoAndBrandingTherapyLake375
        }
    }

    const imageSize = () => {
        if (width > 1279) {
            return {
                width: 1240,
                height: 650
            }
        } else if (width > 767) {
            return {
                maxWidth: 689,
                height: 1115
            }
        } else {
            return {
                maxWidth: 343,
                height: 1337
            }
        }
    }

    return (
        <div className="logo-design-and-branding-therapy-lake-wrapper">
            <div className="therapy-lake-background3">
                <img src={Images.BackgroundTherapyLake1} alt="background"/>
            </div>

            <div className="container">
                <div className="logo-design-and-branding-therapy-lake-box">
                    <h2 className="logo-design-and-branding-therapy-lake-title">
                        Logo Design and Branding
                    </h2>
                    <div className="logo-design-and-branding-therapy-lake-image" style={{...imageSize()}}>
                        <img src={renderImage()} alt="Logo And Branding Therapy Lake"/>
                    </div>
                </div>
            </div>
        </div>
    );
};


import React, {useState} from 'react';
import {AnimationAndText} from "components";
import {EverythingElse, LetsDiscuss} from "./fragments";
import {MyCalendlyComponent} from "./fragments/MyCalendlyComponent";


export const ContactUs = () => {
    const [currentTab, setCurrentTab] = useState('first-tab')


    const handleClickOnTab = (title) => {
        setCurrentTab(title)
    }


    return (
        <div className='contact-us-page'>
            <div className="container">
                <AnimationAndText text='Contact Us'/>

                <div className="tabs">
                    <div className={`tab ${currentTab === 'first-tab' ? 'active' : ''}`}
                         onClick={() => handleClickOnTab('first-tab')}
                    >
                        <p className='first-text'>
                            Let’s discuss your project
                        </p>
                    </div>

                    <div className={`tab ${currentTab === 'second-tab' ? 'active' : ''}`}
                         onClick={() => handleClickOnTab('second-tab')}
                    >
                        <p className='first-text'>
                            Everything Else
                        </p>
                    </div>
                </div>

                <div className="inputs-wrapper">
                    {currentTab === 'first-tab' ? <MyCalendlyComponent/> : <EverythingElse/>}
                </div>

            </div>
        </div>
    );
};


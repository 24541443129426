import {Svg} from "assets/images";

export const aecoProblemAndSolutionCards = [
    {
        Icon: Svg.StarfishProblemIcon,
        title: 'Problem',
        text: "AECO faced challenges in showcasing their expertise and connecting with potential clients due" +
            " to a lack of online presence and cohesive branding, hindering their ability to highlight" +
            " their fast and high-quality electrical services."
    },
    {
        Icon: Svg.StarfishSolutionIcon,
        title: "Solution",
        text:"We developed a custom website and branding strategy for AECO, enhancing their digital visibility " +
            "and effectively communicating their commitment to excellence in electrical contracting. This strategic " +
            "overhaul enabled AECO to better engage with clients and stand out in the competitive market."
    }
]

export const challengesCardsAeco = [
    {
        className:'challenges-aeco-single-card-1',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Digital Visibility",
        text:"Enhancing AECO's online presence to accurately showcase their specialized electrical services and expertise."
    },
    {
        className:'challenges-aeco-single-card-2',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Branding Consistency",
        text:"Developing a cohesive branding strategy that reflects AECO's commitment to fast, high-quality" +
            " work across all digital and physical touchpoints."
    },
    {
        className:'challenges-aeco-single-card-3',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Client Engagement",
        text:"Creating an interactive and informative website to improve client engagement and simplify" +
            " the process of showcasing services and receiving inquiries."
    },

]
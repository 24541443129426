import React from 'react';
import {Images} from "assets/images";
import {useWindowSize} from "utils/hooks";

export const LogoDesignAndBrandingMenuMango = () => {

    const [width] = useWindowSize()

    const renderImage = () => {
        if (width > 1279) {
            return Images.LogoDesignAndBrandingMenuMango1440
        } else if (width > 767) {
            return Images.LogoDesignAndBrandingMenuMango767
        } else {
            return Images.LogoDesignAndBrandingMenuMango375
        }
    }

    const imageSize = () => {
        if (width > 1279) {
            return {
                width: "100%",
                height: 631
            }
        } else if (width > 767) {
            return {
                maxWidth: 688,
                height: 1015,
            }
        } else {
            return {
                maxWidth: 344,
                height: 1404,
            }
        }
    }

    return (
        <div className='logo-design-and-branding-menu-mango-wrapper'>
            <div className="container">
                <div className="logo-design-and-branding-menu-mango-box">
                    <h2 className='logo-design-and-branding-menu-mango-title'>
                        Logo Design and Branding
                    </h2>
                    <div className="logo-design-and-branding-menu-mango-image-block" style={{...imageSize()}}>
                        <img src={renderImage()} alt="logo and design"/>
                    </div>
                </div>
            </div>
        </div>
    );
};


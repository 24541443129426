import React from 'react';
import {useNavigate} from "react-router";
import {motion} from "framer-motion";
import {Button, StrategyCreativity} from "components";
import {OurProjects, Square} from "fragments";
import {Services, BetterBusinessDecision, Professionalism, WhatOurClientsSay} from "./fragments";
import {IndustryExpertise} from "fragments/industryExpertise/industryExpertise";
import {Images} from "assets/images";
import {useWindowSize} from "utils/hooks";


const container = {
    hidden: {
        y: 40,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
    }
};


export const Home = () => {
    const navigate = useNavigate();
    const [width] = useWindowSize()


    const viewMoreHandle = (path) => {
        navigate(`our-projects/${path}`)
    }

    const ourProjects = [
        {
            title: 'Menumango',
            description: 'Advanced Menu System for Restaurants',
            productInfo: `QR-enabled menu with a built-in ordering system, designed to be customer-centric.
            Features quick implementation and high adaptability.`,
            productImg: Images.Menumango,
            onClick: () => viewMoreHandle('menumango'),
            rowReverse: false
        },
        {
            title: 'Fleetforest',
            titleColor: '#049494',
            description: 'Powering America’s Logistics Industry',
            productInfo: 'Featuring several software systems all integrated into one highly effective ecosystem to' +
                ' enhance the operational efficiency of carrier companies in the logistics industry',
            productImg: Images.Fleetforest,
            onClick: () => viewMoreHandle('fleet-forest'),
            rowReverse: false
        },
        {
            title: 'TherapyLake',
            titleColor: '#347AF0',
            description: 'Specialized Software Streamlining ABA Practice Management',
            productInfo: 'HIPAA compliant, modern Practice Management System for ABA healthcare sector.' +
                ' Features enhanced scheduling, billing, posting, credentialing and more.',
            productImg: Images.TheraphyLake,
            onClick: () => viewMoreHandle('therapylake'),
            rowReverse: false
        },
    ]


    return (
        <div className='home-page'>
            <div className="header-banner">
                <Square
                    className='blue-square'
                    rotate={26.189}
                    duration={1}
                    delay={.3}
                />

                <div className="container">
                    <div className="centered-items">
                        <Square
                            className='yellow-square'
                            rotate={width > 1279 ? 26 : width > 767 ? 48 : 25}
                            duration={1.1}
                            delay={.5}
                        />

                        <motion.h2
                            className='header-title'
                            variants={container}
                            initial="hidden"
                            viewport={{amount: .2, once: true}}
                            whileInView='visible'
                            transition={{
                                duration: .9,
                                ease: "easeInOut",
                            }}
                        >
                            Full-Cycle
                            <Square
                                className='red-square'
                                rotate={20}
                                duration={1.1}
                            />
                        </motion.h2>

                        <motion.p
                            className='header-title software'
                            variants={container}
                            initial="hidden"
                            viewport={{amount: .2, once: true}}
                            whileInView='visible'
                            transition={{
                                duration: .9,
                                delay: .1,
                                ease: "easeInOut",
                            }}
                        >
                            Software Development.
                        </motion.p>

                            <motion.p
                                className='text-info'
                                variants={container}
                                initial="hidden"
                                viewport={{amount: .2, once: true}}
                                whileInView='visible'
                                transition={{
                                    duration: .9,
                                    delay: .2,
                                    ease: "easeInOut",
                                }}
                            >
                                From Initial Concept to Final Deployment, We Craft Tailored Software Solutions
                                to Power Your Success
                            </motion.p>

                        <div className='buttons-block'>
                            <Button
                                text='View Our Projects'
                                className='get-in-btn'
                                onClick={() => navigate('/our-projects')}
                            />

                            <button
                                className='lets-talk'
                                onClick={() => navigate('/contact-us')}
                            >
                                Let’s Talk
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <Services/>
            </div>

            <BetterBusinessDecision/>

            <OurProjects
                ourProjects={ourProjects}
            />

            <Professionalism/>

            <div className="container">
                <IndustryExpertise/>
            </div>

            <div className='strategy-header-img'>
                <img src={Images.StrategyHeaderImg} alt="strategy"/>
            </div>

            <StrategyCreativity
                overflowHidden={false}
            />

            <div className="container">
                <WhatOurClientsSay/>
            </div>
        </div>
    );
};



import React from 'react';
import {Svg} from "assets/images";
import {Loader} from "components";

export const Button = (
    {text, onClick, disabled = false, allowIcon = true, background, color, className, type = 'button', loading}
) => {
    return (
        <button
            style={{background: `${background ? background : ''}`}}
            className={`main-button ${className}`}
            onClick={onClick}
            disabled={disabled}
            type={type}
        >
            {loading ?
                <Loader/>
                :
                <>
                    <p className='button-text' style={{color: color ? color : ''}}>
                        {text}
                    </p>

                    {allowIcon
                        &&
                        <Svg.SendSvg fill={`${color ? color : 'white'}`}/>
                    }
                </>
            }
        </button>
    );
};

import React from 'react';
import {AnimationAndText, Button} from "components";
import {OurProjectItem} from "./ourProjectItem";
import {useNavigate} from "react-router";


export const OurProjects = ({ourProjects}) => {
    const navigate = useNavigate()


    return (
        <div className='our-project-wrapper'>
            <div className="container">
                <AnimationAndText
                    text='Recent Projects'
                    className='flex-start'
                />

                {ourProjects?.map((product, i) => (
                    <OurProjectItem
                        key={i}
                        product={product}
                        onClick={product?.onClick}
                        classNameWrapper={ourProjects.length - 1 === i ? 'margin-bottom' : ''}
                    />
                ))}

                <div className="see-more-projects">
                    <Button
                        text='See More Projects'
                        className='view-more-btn'
                        onClick={() => navigate('our-projects')}
                        color='#2A2F35'
                    />
                </div>
            </div>

        </div>
    );
};


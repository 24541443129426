import React from 'react';
import {Card} from "components";

export const ChallengesForProjects = ({classNameWrapper, challengesForProjectsTitle, cards}) => {
    return (
        <div className={`challenges-for-projects-wrapper ${classNameWrapper ? classNameWrapper : ''}`}>
            <div className="container">
                <h2 className='challenges-for-projects-title'>
                    {challengesForProjectsTitle}
                </h2>

                <div className="challenges-for-projects-cards-box">
                    {cards.map(({className, Icon, title, text}, i) => (
                        <Card
                            key={i}
                            index={i}
                            className={`challenges-for-projects-single-card ${className ? className : ''}`}
                            Icon={Icon}
                            title={title}
                            text={text}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};


import React from 'react';
import {
    BannerAeco,
    ChallengesForProjects,
    InfoAboutProjects, LogoDesignAeco,
    ProblemAndSolutionCards,
    TheBriefAeco
} from "../fragments";
import {aecoProblemAndSolutionCards, challengesCardsAeco} from "../fragments/aeco/constants";
import {AboutSomething} from "fragments";
import {Images} from "assets/images";
import {StrategyCreativity} from "components";

export const Aeco = () => {
    return (
        <div className="aeco-page">
            <BannerAeco/>

            <InfoAboutProjects
                client={["ALBRIK ELECTRIC CO"]}
                industry={["Electric Services"]}
                deliverables={["Branding, UI/UX Design", "Website Development"]}
            />

            <TheBriefAeco/>

            <div className="aeco-wrapper-problem-and-solution">
                <ProblemAndSolutionCards
                    className='problem-and-solution-aeco'
                    cards={aecoProblemAndSolutionCards}
                />
            </div>

            <ChallengesForProjects
                classNameWrapper="challenges-aeco-wrapper"
                cards={challengesCardsAeco}
                challengesForProjectsTitle="Challenges"
            />

            <LogoDesignAeco/>

            <AboutSomething
                className="about-something-aeco-wrapper"
                img={Images.ConclusionAeco}
                title="Conclusion"
                description="The successful launch of AECO's website and branding initiative marks a significant leap
                 forward in their digital and market presence. This strategic enhancement not only showcases AECO's
                 commitment to quality and efficiency in electrical contracting but also sets a new benchmark in
                  client engagement and service accessibility, positioning AECO as a leader in the industry."
            />

            <StrategyCreativity/>
        </div>
    );
};


import React from 'react';
import {NavLink} from "react-router-dom";

export const TheBriefBy = () => {
    return (
        <div className="the-brief-wrapper-by">
            <div className="container">
                <div className="the-brief-box-by">
                    <h3 className="the-brief-by-title">
                        The Brief
                    </h3>

                    <p className="the-brief-by-description">
                        TherapyLake is management system to help healthcare practices manage their business,
                        streamline operations, provide higher quality patient care and improve revenue collection.
                        We are committed to simplifying management by providing our users with a suite of tools and
                        services that enhance efficiency and optimize workflows.
                    </p>

                    <NavLink
                        to="https://therapylake.com/"
                        className="the-brief-by-link"
                    >
                        Visit website
                    </NavLink>
                </div>
            </div>
        </div>
    );
};


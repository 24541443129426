import React from 'react';
import {OurExperience, OurTeamMembers, PrinciplesOfWork, Strengths} from "./fragments";
import {StrategyCreativity} from "components";
import {AboutSomething} from "fragments";
import {Images} from "assets/images";


export const AboutUs = () => {
    return (
        <div className='about-as-page'>
            <AboutSomething
                className="about-as-who-we-are"
                animationText='Welcome to Eachbase'
                preDescription={
                `At Eachbase, we unite technology, creativity, and strategy to turn your digital aspirations into reality.As your strategic partners in innovation, we're here to guide you through the ever-evolving digital landscape with solutions that are not just visionary but viable.
                        
With a focus on agile development and data-driven insights, we prioritize swift market entry without sacrificing quality, ensuring your project is built for success from day one. Our commitment is  to your vision, offering personalized guidance every step of the way to secure your competitive edge.
                 
Let's shape the future of your business together 🚀
                
Warmly,
The Eachbase Team`
                }
                img={Images.aboutAs}
            />

            <Strengths/>

            <OurExperience/>

            <PrinciplesOfWork/>

            <OurTeamMembers/>

            <StrategyCreativity/>
        </div>
    );
};


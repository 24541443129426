import React from 'react';
import {Button, InputMain} from "../../components";
import {useForm} from "react-hook-form";
import {Svg} from "assets/images";

export const JoinUs = () => {

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm()

    const onSubmit = (data) => {
        console.log(data)
    }

    return (
        <div className='join-us-page'>
            <div className="container">
                <div className="join-us-page-title">
                    Help us on a mission to craft digital products
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className='inputs-form-jon-us'>
                    <InputMain
                        {...register('fName')}
                        label='Full Name'
                        error={errors.fName}
                        classNameWrapper='margin-bottom'
                        required
                    />

                    <InputMain
                        {...register('email')}
                        label='Email'
                        type='email'
                        classNameWrapper='margin-bottom'
                        required
                    />

                    <InputMain
                        {...register('phoneNumber')}
                        label='Phone Number'
                        classNameWrapper='margin-bottom'
                        type='number'
                        required
                    />

                    <InputMain
                        {...register('jobTitle')}
                        label='Job Title'
                        classNameWrapper='margin-bottom'
                        required
                    />

                    <InputMain
                        {...register('yourMessage')}
                        label='Your Message'
                        classNameWrapper='margin-bottom fullWidth'
                        required
                    />

                    <div className="buttons-box">
                        <label className='attach-file-label'>
                            <input
                                type='file'
                                className='attach-file-input'
                            />

                            <Svg.AttachFileSvg
                                className='attach-file-svg'
                            />

                            <p
                                className='attach-file-text'>
                                Attach File
                            </p>
                        </label>


                        <Button
                            text='Send Message'
                            className='send-button'
                            onClick={() => {
                            }}
                        />
                    </div>

                </form>
            </div>

        </div>
    );
};


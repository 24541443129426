import React, {useState, forwardRef} from "react";
import _ from 'lodash'
import {Drawer, IconButton, List} from "@mui/material";
import {MainMenuList} from "./MainMenuList";
import {useNavigate} from "react-router";
import {Svg} from "assets/images";
import {useWindowSize} from "utils/hooks";
import {menuOptions} from "../constants";

export const MainMenu = forwardRef(({openingMenu, toggleManu, isOpenSubOption, setOpeningSubOption}, ref) => {
    const navigate = useNavigate()
    const [width] = useWindowSize()

    const toggleDrawer = (item) => {
        if (item?.subOption) {
            setOpeningSubOption(item?.subOption)
            if (isOpenSubOption.length) {
                setOpeningSubOption([])
            }
        } else {
            toggleManu()
            setOpeningSubOption([])
            navigate(item?.link)
        }
    };

    const list = () => (
        <div className='menu-list-block'>
            {((width < 768 && !isOpenSubOption.length) || width > 768) &&

                <List className='menu-list'>
                    {_.map(menuOptions, (item, index) => {
                        return (
                            <MainMenuList
                                item={item}
                                key={index}
                                isOpenSubOption={isOpenSubOption}
                                toggleDrawer={() => toggleDrawer(item)}
                            />
                        )
                    })}

                </List>
            }


            {!!isOpenSubOption?.length &&
                <div className='sub-options-block-main-menu'>
                    {(width < 768)
                        &&

                        <IconButton
                            className="back-to-btn-svg-block"
                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            onClick={() => setOpeningSubOption([])}
                        >
                            <Svg.ArrowSvg className="back-to-btn-svg"/>

                        </IconButton>
                    }


                    <List
                        className='menu-list menu-list-margin'
                    >
                        {_.map(isOpenSubOption, (i, index) => (
                            <MainMenuList
                                item={i}
                                key={index}
                                toggleDrawer={() => toggleDrawer(i)}
                            />
                        ))}
                    </List>
                </div>
            }
        </div>
    );

    return (
        <Drawer
            classes={ref}
            anchor="left"
            open={openingMenu}
            onClose={toggleManu}
        >
            {list()}
        </Drawer>
    );
})

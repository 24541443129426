import React from 'react';
import {motion} from "framer-motion";


const textVariant = {
    visible: {
        y: 0,
    },
    hidden: {
        y: 55,
    }
}

const titleVariant = {
    visible: {
        y: 0,
    },
    hidden: {
        y: 40,
    }
}

const imgVariant = {
    visible: {
        height: '100%',
    },
    hidden: {
        height: '0',

    }
}

export const BusinessFeaturesExample = ({classNameWrapper, classNameImage, img, title, description}) => {
    return (
        <div className={`business-features-example ${classNameWrapper ? classNameWrapper : ''}`}>
            <div className='overflow-hidden'>
                <div
                    className="business-description"
                >
                    <motion.h3
                        className="title"
                        initial='hidden'
                        viewport={{amount: .2, once: true}}
                        whileInView='visible'
                        variants={titleVariant}
                        transition={{
                            duration: .9,
                            delay: .1,
                            ease: "easeInOut",
                        }}
                    >
                        {title}
                    </motion.h3>

                    <motion.p
                        className='description'
                        initial='hidden'
                        viewport={{amount: .2, once: true}}
                        whileInView='visible'
                        variants={textVariant}
                        transition={{
                            duration: .9,
                            delay: .3,
                            ease: "easeInOut",
                        }}
                    >
                        {description}
                    </motion.p>
                </div>
            </div>

            <motion.div
                className={`img ${classNameImage}`}
                initial='hidden'
                viewport={{amount: .2, once: true}}
                whileInView='visible'
                variants={imgVariant}
                transition={{
                    duration: .9,
                    ease: "easeInOut",
                }}
            >
                <img src={img} alt="business"/>
            </motion.div>
        </div>
    );
};



import React from 'react';
import {
    BannerTherapyLakeAndInfo,
    ChallengesForProjects, LogoDesignTherapyLake, MobileApplicationsTherapyLake,
    ProblemAndSolutionCards, UiDesignTherapyLake,
    WorkflowTherapyLake
} from "../fragments";
import {AboutSomething} from "fragments";
import {Images} from "assets/images";
import {challengesCardsTherapyLake, therapyLakeProblemAndSolutionCards} from "../fragments/therapyLake/constants";
import {StrategyCreativity} from "components";


export const TherapyLake = () => {
    return (
        <div className='therapy-lake-page'>

            <BannerTherapyLakeAndInfo/>

            <div className="wrapper-for-backgrounds-therapy-lake">
                <div className="therapy-lake-background1">
                    <img src={Images.BackgroundTherapyLake1} alt="background"/>
                </div>

                <div className="therapy-lake-background2">
                    <img src={Images.BackgroundTherapyLake1} alt="background"/>
                </div>

                <div className="therapy-lake-the-brief-problem-solution-workflow-wrapper">
                    <AboutSomething
                        className='about-something-therapy-lake-wrapper  therapy-lake-the-brief'
                        title="The Brief"
                        description="TherapyLake is management system to help healthcare practices manage their business,
                     streamline operations, provide higher quality patient care and improve revenue collection.
                      We are committed to simplifying management by providing our users with a suite of tools and
                      services that enhance efficiency and optimize workflows. "
                        link={{name: 'Visit website', href: 'https://therapylake.com/'}}
                        img={Images.TheBriefTherapyLake}
                    />

                    <ProblemAndSolutionCards
                        className='problem-and-solution-therapy-lake'
                        cards={therapyLakeProblemAndSolutionCards}
                    />

                    <WorkflowTherapyLake/>

                </div>
            </div>


            <ChallengesForProjects
                classNameWrapper="challenges-therapy-lake"
                cards={challengesCardsTherapyLake}
                challengesForProjectsTitle="Challenges"
            />

            <LogoDesignTherapyLake/>

            <UiDesignTherapyLake/>

            <MobileApplicationsTherapyLake/>

            <AboutSomething
                className='about-something-therapy-lake-wrapper  therapy-lake-conclusion'
                title="Conclusion"
                description="TherapyLake is poised to revolutionize the ABA practice by solving critical operational
                challenges through its comprehensive management platform. By streamlining client onboarding,
                 staff scheduling, and financial workflows, TherapyLake enables practitioners to refocus on what
                  truly matters—patient care and clinical outcomes. It's a leap towards greater efficiency and
                  clinical success in the ABA field."
                img={Images.ConclusionTherapyLake}
            />

            <StrategyCreativity/>
        </div>
    );
};


import {Svg} from "assets/images";

export const therapyLakeProblemAndSolutionCards = [
    {
        Icon: Svg.StarfishProblemIcon,
        title: 'Problem',
        text: "ABA practices grapple with administrative complexities, from client onboarding to billing. Managing " +
            "these intricate processes can detract from clinical care, leading to inefficiencies and " +
            "reduced focus on patient outcomes."
    },
    {
        Icon: Svg.StarfishSolutionIcon,
        title: "Solution",
        text:"TherapyLake addresses these challenges by providing an all-in-one ABA practice management platform." +
            " It simplifies every aspect of practice management, allowing therapists to concentrate on delivering" +
            " high-quality care while the system streamlines scheduling, documentation, billing, and payroll."
    }
]

export const challengesCardsTherapyLake = [
    {
        className:'challenges-therapy-lake-single-card-1',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Comprehensive Client Management",
        text:"Managing an ABA practice requires a delicate balance between clinical work and administrative tasks." +
            " Clinicians often struggle to efficiently onboard new clients and maintain detailed records without " +
            "detracting from patient care."
    },
    {
        className:'challenges-therapy-lake-single-card-2',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Staff Coordination and Scheduling",
        text:"Scheduling and managing staff across various clients and sessions can be intricate and time-consuming." +
            " Ensuring each client receives the necessary attention without overextending staff resources is a common hurdle."
    },
    {
        className:'challenges-therapy-lake-single-card-3',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Streamlined Billing and Payroll",
        text:"ABA practices face the daunting task of managing billing cycles, insurance interactions, and payroll" +
            " with accuracy and compliance. Inefficiencies in these areas can lead to financial discrepancies and" +
            " distract from the primary focus of patient therapy."
    },

]
import React from 'react';
import {useWindowSize} from "utils/hooks";
import {Images} from "assets/images";

export const UiUxDesignForStarfish = () => {
    const [width] = useWindowSize()

    const renderImage = () => {
        if (width > 1279) {
            return Images.UiUxStarfish1440
        } else if (width > 767) {
            return Images.UiUxStarfish767
        } else {
            return Images.UiUxStarfish375
        }
    }

    const imageSize = () => {
        if (width > 1279) {
            return {
                width: "100%",
                height: 1048
            }
        } else if (width > 767) {
            return {
                maxWidth: 767,
                height: 945
            }
        } else {
            return {
                maxWidth: 375,
                height: 1201
            }
        }
    }
    return (
        <div className='ui-ux-design-for-starfish-wrapper'>
            <div className="Ui-Ux-design-for-starfish-image-block" style={{...imageSize()}}>
                <img src={renderImage()} alt="Ui Design"/>
            </div>
        </div>
    );
};


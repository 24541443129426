import React from 'react';
import {motion} from 'framer-motion'
import {Images} from "assets/images";

export const UiDesignTherapyLake = () => {

    const animationVariant = {
        visible: {
            scale: 1,
            opacity: 1
        },

        hidden: {
            scale: .8,
            opacity: 0
        }
    }

    const animTitleVariant = {
        visible: {
            y: 0,
            opacity: 1
        },

        hidden: {
            y: 20,
            opacity: 0
        }
    }

    return (
        <div className="ui-design-therapy-lake-wrapper">
            <div
                className="therapy-lake-background4"
            >
                <img src={Images.BackgroundTherapyLake1} alt="background"/>
            </div>

            <motion.h2
                className="ui-design-therapy-lake-title"
                initial="hidden"
                whileInView='visible'
                variants={animationVariant}
                viewport={{amount: .2, once: true}}
                transition={{
                    duration: 1,
                    ease: "easeInOut",
                }}
            >
                UI Design
            </motion.h2>

            <motion.div
                className="ui-design-therapy-lake-macbook"
                initial="hidden"
                whileInView='visible'
                variants={animationVariant}
                viewport={{amount: .2, once: true}}
                transition={{
                    duration: 1,
                    ease: "easeInOut",
                }}
            >
                <img src={Images.MacbookProTherapyLake} alt="macBook Pro"/>
            </motion.div>

            <div
                className="ui-design-therapy-lake-screens"
            >
                <img src={Images.TherapyLakeScreen} alt="Screen 1"/>
            </div>
        </div>
    );
};


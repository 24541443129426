import React from 'react';
import {Svg} from "assets/images";

export const BannerBy = () => {
    return (
        <div className="by-banner-wrapper">
            <Svg.LogoBy className="by-banner-logo"/>
            <div className="by-banner-title-description">
                <h2 className="by-banner-title">
                    Optimize healthcare practice management
                </h2>
                <p className="by-banner-description">
                    TherapyLake is management system to help healthcare practices manage their business,
                    streamline operations, provide higher quality patient care and improve revenue collection.
                </p>
            </div>
        </div>
    );
};


import React from 'react';
import {Images} from "assets/images";
import {useWindowSize} from "utils/hooks";


export const LogoDesignAndBranding = () => {
    const [width] = useWindowSize()

    const renderImage = () => {
        if (width > 1279) {
            return Images.LogoDesignAndBrandingFleet1440
        } else if (width > 767) {
            return Images.LogoDesignAndBrandingFleet767
        } else {
            return Images.LogoDesignAndBrandingFleet344
        }
    }

    const imageSize = () => {
        if (width > 1279) {
            return {
                width: 1092,
                height: 814
            }
        } else if (width > 767) {
            return {
                maxWidth: 690,
                height: 1104
            }
        } else {
            return {
                maxWidth: 373,
                height: 1184
            }
        }
    }

    return (
        <div className='logo-Design-and-branding-wrapper'>
            <div className="container">
                <div className="logo-Design-and-branding-box">
                    <h2 className='logo-Design-and-branding-title'>
                        Logo Design and Branding
                    </h2>

                    <div className='logo-design-and-branding-image-fleet' style={{...imageSize()}}>
                        <img src={renderImage()} alt="logo an design"/>
                    </div>
                </div>
            </div>
        </div>
    );
};


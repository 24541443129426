import React from 'react';
import Carousel from "nuka-carousel";
import {AnimationAndText, Card} from "components";
import {industryExpertise} from "./constants";
import {IsInView} from "utils";
import {motion} from "framer-motion";


export const IndustryExpertise = () => {

    return (
        <div className='industry-expertise-wrapper'>
            <AnimationAndText
                text='Industry Expertise '
                className='flex-start'
            />

            <div className="industry-cards">
                {industryExpertise.map(({Icon, title, description}, i) => (
                    <Card
                        Icon={Icon}
                        title={title}
                        text={description}
                        classNameInView='industry-single-card_view'
                        className='industry-single-card'
                        key={i}
                    />
                ))}
            </div>

            <motion.div
                className='industry-cards-mobile'
                initial={{opacity: 0, y: 200}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 1}}
            >
                <Carousel
                    cellAlign="center"
                    slidesToShow={1}
                    cellSpacing={10}
                    slideIndex={1}
                >
                    {industryExpertise.map(({Icon, title, description}, i) => (
                        <Card
                            index={i}
                            Icon={Icon}
                            title={title}
                            text={description}
                            className='industry-single-card-mobile'
                            key={i}
                        />
                    ))}
                </Carousel>
            </motion.div>
        </div>
    );
};


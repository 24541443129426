import {Images, Svg} from "assets/images";
import {ReactComponent as TimeManagementSvg} from "../../../assets/images/svg/TimeManagementSvg.svg";
import {ReactComponent as LocationSvg} from "../../../assets/images/svg/LocationSvg.svg";
import {ReactComponent as IterationSvg} from "../../../assets/images/svg/IterationSvg.svg";
import MaratDefault from "../../../assets/images/img/teammembers/Marat-Default.png";

export const strengthsCard = [
    {
        title: 'Agile Collaboration',
        text: 'Working closely with you, we ensure an agile journey from concept to market. By focusing on your' +
            ' vision, we navigate technical complexities, driving fast, impactful results tailored to your business' +
            ' needs.',
    },
    {
        title: 'Data-Driven Development',
        text: 'Starting with a Minimum Viable Product (MVP), we rapidly bring your ideas to market. Early insights' +
            ' guide our iterative enhancements, aligning each step with user feedback for value-driven, intelligent' +
            ' growth.',
    },
    {
        title: 'Continuous Innovation',
        text: 'Innovation and deep industry insights keep your product competitive. As strategic partners, we ensure' +
            ' your offerings not only meet the market demands but set new benchmarks.',
    },

]

export const principlesCard = [
    {
        title: 'Partnership First',
        text: 'We see you as a strategic partner, not just a client. Our aim is to build lasting relationships' +
            ' where mutual growth is the primary goal.',
        lineColor: '#2A2F35'
    },
    {
        title: 'Mutual Success',
        text: 'Your victories are ours. We invest deeply in your success, understanding that our growth is' +
            ' directly tied to yours. Together, we thrive ',
        color: '#FFF',
        backgroundColor: '#2BB654',
        lineColor: '#FFF'
    },
    {
        title: 'Transparency',
        text: 'We treat your projects as our own, ensuring open communication and trust at every step ' +
            'towards shared success.',
        color: '#FFF',
        backgroundColor: '#347AF0',
        lineColor: '#FFF'
    },
    {
        title: 'More Than Code',
        text: 'Beyond software development, we offer advice, share insights, and suggest strategies to ensure your' +
            ' project\'s success.',
        lineColor: '#2A2F35'
    },
]

export const membersCard = [
    {
        image: Images.MaratDefault,
        imageHovered: Images.MaratHover,
        title: 'Marat Martirosyan',
        description: 'Office Manager'
    }, {
        image: Images.DavitDefault,
        imageHovered: Images.DavitHover,
        title: 'Davit Petrosyan',
        description: 'Lead Front-End Developer'
    }, 
    // {
    //     image: Images.MherDefault,
    //     imageHovered: Images.MherHover,
    //     title: 'Mher Petrosyan',
    //     description: 'Back-End Developer'
    // }, 
    {
        image: Images.ShushanDefault,
        imageHovered: Images.ShushanHover,
        title: 'Shushan Abovyan',
        description: 'UI/UX Designer'
    }, {
        image: Images.VazgenDefault,
        imageHovered: Images.VazgenHover,
        title: 'Vazgen Galstyan',
        description: 'Mobile Developer'
    }, {
        image: Images.KnarikDefault,
        imageHovered: Images.KnarikHover,
        title: 'Knarik Makaryan',
        description: 'UI/UX Designer'
    }, 
    // {
    //     image: Images.AniDefault,
    //     imageHovered: Images.AniHover,
    //     title: 'Ani Ghazaryan',
    //     description: 'Content Writer'
    // }, 
    {
        image: Images.GarikDefault,
        imageHovered: Images.GarikHover,
        title: 'Garik Hovhanisyan',
        description: 'Back-End Developer'
    }, {
        image: Images.ArturDefault,
        imageHovered: Images.ArturHover,
        title: 'Artur Meliqyan',
        description: 'Front-End Developer'
    },
    // {
    //     image: Images.MariDefault,
    //     imageHovered: Images.MariHover,
    //     title: 'Mari Dass',
    //     description: 'Graphic Designer'
    // },
    // {
    //     image: Images.PetrDefault,
    //     imageHovered: Images.PetrHover,
    //     title: 'Petr Razumovski',
    //     description: 'Digital Marketing Specialist'
    // },
]


export const ourExperience = [
    {
        title: 'End-to-end management',
        description: 'Our expertise encompasses complete project lifecycle management, from initial concept ' +
            'to deployment and maintenance, ensuring seamless execution.',
        Svg: Svg.TimeManagementSvg

    },
    {
        title: 'Based in Los Angeles, CA',
        description: 'Strategically located in Los Angeles, we are at the heart of innovation,' +
            ' serving clients globally with cutting-edge solutions.',
        Svg: Svg.LocationSvg

    },
    {
        title: 'Agile Teams',
        description: 'We operate with agile methodologies at our core, ensuring flexibility, rapid delivery, and continuous improvement and delivery across all projects.',
        Svg: Svg.IterationSvg

    },
]
import {Svg} from "assets/images";

export const byProblemAndSolutionCards = [
    {
        Icon: Svg.StarfishProblemIcon,
        title: 'Problem',
        text: "The business is an online calculator for transportation companies for finding profitability loads." +
            " Companies can add their own trucks and some information about trucks. Next, a company can add loads in" +
            " the system and select trucks from its own list, after which the system will calculate the profit." +
            " The main idea in the business is to get the best loads and optimize the trips. The system will allow" +
            " the user to add a load for each truck and will keep track of the previous loads to calculate the overall" +
            " profit from all the loads a specific truck did."
    },
    {
        Icon: Svg.StarfishSolutionIcon,
        title: "Solution",
        text:"The business is an online calculator for transportation companies for finding profitability loads." +
            " Companies can add their own trucks and some information about trucks. Next, a company can add loads" +
            " in the system and select trucks from its own list, after which the system will calculate the profit." +
            " The main idea in the business is to get the best loads and optimize the trips. The system will allow " +
            "the user to add a load for each truck and will keep track of the previous loads to calculate the overall" +
            " profit from all the loads a specific truck did."
    }
]


export const challengesCardsBy = [
    {
        className:'challenges-by-single-card-1',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Wireframe",
        text:"The business is an online calculator for transportation companies for finding profitability loads." +
            " Companies can add their own trucks and some information about trucks."
    },
    {
        className:'challenges-by-single-card-2',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Wireframe",
        text:"The business is an online calculator for transportation companies for finding profitability loads." +
            " Companies can add their own trucks and some information about trucks."
    },
    {
        className:'challenges-by-single-card-3',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Wireframe",
        text:"The business is an online calculator for transportation companies for finding profitability loads." +
            " Companies can add their own trucks and some information about trucks."
    },

]
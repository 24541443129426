import React, {useEffect, useState} from 'react';
import {AnimationAndText, Card} from "components";
import Carousel from "nuka-carousel";
import {clientsSey} from "./constants";
import {useWindowSize} from "utils/hooks";

export const WhatOurClientsSay = () => {
    const [slidesToShow, setSliderToShow] = useState(3)
    const [width] = useWindowSize()

    useEffect(() => {
        if (width > 1279) {
            setSliderToShow(3)
        } else if (width > 767) {
            setSliderToShow(2)
        } else {
            setSliderToShow(1)
        }
    }, [slidesToShow, width])


    return (
        <div className='what-our-clients-say'>
            <AnimationAndText
                text='What Our Clients Say'
                className='flex-start'
            />

            <div className="client-cards-carousel_wrapper">
                <Carousel
                    cellAlign="center"
                    slidesToShow={slidesToShow}
                    className='client-cards-carousel'
                    adaptiveHeight={true}
                    cellSpacing={10}
                    slideIndex={1}
                >
                    {clientsSey.map(({text, Icon, userInfo}, i) => (
                        <Card
                            index={i}
                            key={i}
                            text={text}
                            Icon={Icon}
                            userInfo={userInfo}
                            className='client-single-card'
                        />
                    ))}
                </Carousel>
            </div>


        </div>
    );
};


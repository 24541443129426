import {Images} from "assets/images";
import UserDarlence from "../../../assets/images/img/UserDarlence.png";


export const userPersonasCard = [
    {
        userInfo: {
            userImg: Images.UserMark,
            fullName: 'Mark Simson',
            profession: 'Truck Driver',
            yearOld: '45 years old'
        },
        story: 'Mark, a 45-year-old truck driver, focuses on optimizing routes to reduce time, fuel, and costs.' +
            ' He values reliable communication tools for coordination with his dispatcher, clients, and peers,' +
            ' essential for seamless deliveries. His challenges include adapting to last-minute schedule or route' +
            ' changes and needing clear, timely instructions to avoid disruptions and meet deadlines effectively.',
        goals: ['He aims to optimize his routes to save time, fuel, and operational costs.',
            'He needs reliable communication tools to stay connected with his dispatcher, clients, and other' +
            ' drivers, especially when coordinating deliveries.'],
        painPoints: [
            'Mark need to stay updated frequent last-minute changes to their schedules or routes.',
            'Drivers need clear and timely instructions to perform their tasks effectively.',
            'Sudden changes can disrupt their plans and potentially lead to difficulties in meeting delivery deadlines.'
        ],
        className: 'user-Mark'
    },

    {
        userInfo: {
            userImg: Images.UserAlex,
            fullName: 'Alex Johnson',
            profession: 'Operations Manager',
            yearOld: '28 years old'
        },
        story: "Alex is a 38-year-old logistics coordinator at a mid-sized manufacturing company. He's responsible for" +
            " overseeing the transportation of raw materials to the factory and finished goods to distributors. Alex's goal is to streamline logistics operations to enhance efficiency and reduce costs.",
        goals: ['He aims to reduce operational costs, optimize routes, and make informed decisions that positively impact the bottom line.'],
        painPoints: [
            'Longer delivery times, increased fuel costs, and lower overall efficiency',
            'Limited human interaction due to the pandemic',
            'Inaccurate tracking of expenses related to fuel, maintenance, and repairs can lead to ' +
            'inflated operational costs, affecting the company\'s profitability.\n'
        ],
        className: 'user-Alex'
    },
]

export const userPersonasCardMenuMango = [
    {
        userInfo: {
            userImg: Images.UserEmma,
            fullName: 'Emma Thompson',
            profession: 'The Tech-Savvy Restaurateur',
            yearOld: '34 years old'
        },
        story: "She is the owner of a small restaurant. She’s busy managing the staff to increase their" +
            " productivity.Now she’s looking for ways to optimize the ordering process, enhance the customer " +
            "experience and increase the sales.",
        goals: [
            "Improve her staff’s productivity.",
            "Bring more satisfied clients.",
            "Increase the sales, Increase her annual revenue.",
            "Stand up to the competition.",
            "Keep up with the latest technology trends."
        ],
        painPoints: [
            "Emma doesn’t want to have delays in the ordering process.",
            "She doesn't want to have high rates of customer complaints.",
            "Emma doesn’t want to overspend on operations.",
            "She doesn't want to have decline in sales."
        ],
        className: 'user-Mark'
    },

    {
        userInfo: {
            userImg: Images.UserDarlence,
            fullName: 'Darlene Robertson',
            profession: 'General manager',
            yearOld: '36 years old'
        },
        story: "Michael is the General Manager at a large chain restaurant. Married and deeply invested in his " +
            "team's growth, he's constantly exploring strategies to boost staff efficiency and enhance productivity " +
            "in a fast-paced culinary environment.",
        goals: [
            "Optimize internal processes.",
            "Decrease the customer wait time.",
            "Increase the customer satisfaction.",
            "Increase the workflow efficiency and make the workflow smoother."],
        painPoints: [
            "Michael doesn’t want to have delays in the ordering process.",
            "He doesn't want to have high rates of customer complaints.",
            "Michael doesn't want to have decline in sales."
        ],
        className: 'user-Alex'
    },
]
import React, {useRef, useState} from 'react';
import {toast} from "react-toastify";
import axios from "axios";
import ReCAPTCHA from 'react-google-recaptcha'
import {useForm} from "react-hook-form";
import {Button, InputMain, MaskInput} from "components";

export const EverythingElse = () => {
    const [notRobot, toggleNotRobotCheckbox] = useState(false)
    const [loading, setLoading] = useState(false)
    const recaptchaRef = useRef(null)


    const {
        register,
        handleSubmit,
        control,
    } = useForm()

    const onSubmit = async (data) => {
        const onlyNumbers = data?.phoneNumber.replace(/\D/g, '')

        const sendData = {
            fullName: data?.fName,
            email: data?.email,
            message: data?.yourMessage,
            phoneNumber: onlyNumbers,
            companyName: data?.companyName,
        }

        setLoading(true)
        try {
            if (notRobot) {
                await axios.post('/mailer/contactForm', sendData)
            }
            toast.success('Message was sent')
        }  finally {
            setLoading(false)
        }
    }

    function onChange(val) {
        toggleNotRobotCheckbox(val)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='inputs-form'>
            <InputMain
                {...register('fName', {required: 'This Field is required'})}
                control={control}
                label='Full Name'
                classNameWrapper='margin-bottom'
            />

            <InputMain
                {...register('email', {required: 'This Field is required'})}
                control={control}
                label='Email'
                type='email'
                classNameWrapper='margin-bottom'
                required
            />

            <MaskInput
                {...register('phoneNumber', {required: 'This Field is required'})}
                control={control}
                label='Phone Number'
                classNameWrapper='margin-bottom'
                type='number'
            />

            <InputMain
                {...register('companyName', {required: 'This Field is required'})}
                control={control}
                label='Company Name'
                classNameWrapper='margin-bottom'
            />

            <InputMain
                {...register('yourMessage', {required: 'This Field is required'})}
                control={control}
                label='Your Message'
                classNameWrapper='margin-bottom fullWidth'
            />

            <div className="checkbox-button-box">
                <div className="checkboxes-wrapper">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        onChange={onChange}
                    />
                </div>

                <Button
                    disabled={!notRobot}
                    loading={loading}
                    text='Send Message'
                    className='send-button'
                    type='submit'
                />
            </div>

        </form>
    );
};


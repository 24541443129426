import React from 'react';
import {motion} from 'framer-motion'


export const Square = ({className, rotate, duration,delay}) => {
    const animationVariant = {
        visible: {
            rotate: [-rotate, rotate],
        },

        hidden: {
            rotate: [-rotate, rotate],
        }
    }

    return (

        <motion.div
            className={`square-wrapper ${className ? className : ''}`}
            initial="hidden"
            whileInView='visible'
            variants={animationVariant}
            viewport={{amount: .2, once: true}}
            transition={{
                duration: duration || 2.5,
                ease: "easeInOut",
                delay: delay || 0,
                repeatDelay: 1
            }}
        />

    );
};






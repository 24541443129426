import React from 'react';
import {NavLink} from "react-router-dom";
import {motion} from "framer-motion";
import {AnimationAndText} from "components";
import {Svg} from "assets/images";

// const titleVariant = {
//     visible: {
//         opacity: 1,
//         y: 0
//     },
//     hidden: {
//         opacity: 0,
//         y: 50
//     }
// }

const boxAnimVariant = {
    visible: {
        opacity: 1,
        y: 0
    },
    hidden: {
        opacity: 0,
        y: 100
    }
}

const imgVariant = {
    visible: {
        transform: 'scale(1)',
        opacity: 1,
    },
    hidden: {
        transform: 'scale(.8)',
        opacity: 0,
    }
}

export const AboutSomething = (
    {
        animationText,
        title,
        description,
        preDescription,
        secondDescription,
        img,
        whatWeDo,
        link,
        fontColor,
        className,
        id
    }
) => {
    return (
        <div className="container">
            <div className={`about-something-wrapper ${className ? className : ''}`} id={id && id}>
                <motion.div
                    id={id}
                    className="about-something-box"
                    initial="hidden"
                    whileInView='visible'
                    variants={boxAnimVariant}
                    viewport={{amount: .2, once: true}}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                        repeatDelay: 1
                    }}
                >
                    <div className='overflow-hidden'>

                        {animationText &&
                            <AnimationAndText
                                text={animationText}
                                className='about-something-anim-text'
                            />
                        }

                        {title &&
                            <div className='overflow-hidden'>
                                <motion.h2
                                    className='about-something-title'
                                    style={{color: fontColor ? fontColor : ''}}
                                >
                                    {title}
                                </motion.h2>
                            </div>
                        }

                        {description &&
                            <div
                                className='overflow-hidden'
                            >
                                <motion.p
                                    className='about-something-about-description'
                                    style={{color: fontColor ? fontColor : ''}}
                                >
                                    {description}
                                </motion.p>
                            </div>
                        }

                        {preDescription &&
                            <div
                                className='about-something-about-description'
                            >
                                <motion.pre
                                    style={{color: fontColor ? fontColor : ''}}
                                >
                                    {preDescription}
                                </motion.pre>
                            </div>
                        }

                        {secondDescription &&
                            <div className='overflow-hidden'>
                                <motion.p
                                    className='about-something-about-description'
                                    style={{color: fontColor ? fontColor : ''}}
                                >
                                    {secondDescription}
                                </motion.p>
                            </div>

                        }


                        {whatWeDo &&
                            <ul className='what-we-do-block'>
                                {whatWeDo.map((text, i) => (
                                    <li className='what-we-do-list' style={{overflow: 'hidden'}} key={i}>
                                        <Svg.CheckSvg/>
                                        <motion.p
                                            className='what-we-do-text'
                                        >
                                            {text}
                                        </motion.p>
                                    </li>
                                ))}
                            </ul>}

                        {link &&
                            <NavLink to={link.href} target='_blank' className='about-something-link'>
                                {link.name}
                            </NavLink>
                        }

                    </div>
                </motion.div>


                <motion.div
                    className="about-something-image"
                    initial='hidden'
                    whileInView='visible'
                    variants={imgVariant}
                    viewport={{amount: .2, once: true}}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                    }}
                >
                    <img src={img} alt="about us"/>
                </motion.div>

            </div>
        </div>
    )
        ;
};




export const menuOptions = [
    {
        text: 'About Us',
        link: 'about-us'
    },
    {
        text: 'Our  Projects',
        link: 'our-projects'
    },
    {
        text: 'Services',
        link: 'services',
        // subOption: [
        //     {
        //         text: "Services",
        //         link: 'services#services',
        //     },
        //     {
        //         text: "UI/UX Designer",
        //         link: 'services#uiUx'
        //     },
        //     {
        //         text: "Marketing",
        //         link: 'services#marketing'
        //     }
        // ]
    },

    // {
    //     text: 'Our Expertise'
    // }
    // ,

    // {
    //     text: 'Blog',
    //     link: 'blog'
    // },

    {
        text: 'Contact Us',
        link: 'contact-us'
    }
]

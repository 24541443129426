import React from 'react';
import {Svg} from "assets/images";
import {keyFeatures} from "./constants";


export const KeyFeatures = () => {
    return (
        <div className='key-features-wrapper'>
            <div className="container">
                <div className="key-features-block">
                    <h2 className='key-features-title'>
                        Key Features
                    </h2>

                    <div className="key-features-items-block">
                        {keyFeatures.map((item, i) => (
                            <div className={`key-features-items ${item.className ? item.className : ''}`} key={i}>
                                <Svg.CheckSvg className="key-features-svg"/>
                                {item.title}
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    );
};


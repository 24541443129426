import {Svg} from "../../assets/images";

export const industryExpertise = [
    {
        Icon: Svg.Logistics,
        title: 'Logistics and Transportation',
        description: 'We excel in efficient goods movement and supply chain optimization. Our expertise includes ' +
            'route planning, freight management, and real-time tracking, ensuring timely deliveries and operational' +
            ' excellence.'
    }, {
        Icon: Svg.Healthcare,

        title: 'Healthcare',
        description: 'We specialize in improving patient care with technology integration. Our work enhances' +
            ' healthcare delivery, streamlines operations, and ensures data security, focusing on better outcomes' +
            ' and accessibility.'
    }, {
        Icon: Svg.Food,
        title: 'Restaurant',
        description: 'We innovate in the restaurant industry with operational efficiency and customer engagement' +
            ' strategies. Our focus is on menu optimization, service improvement, and leveraging technology for' +
            ' enhanced dining experiences.'
    },
    {
        Icon: Svg.Insurance,
        title: 'E-Commerce',
        description: 'We drive e-commerce growth through user-centric design and seamless shopping experiences. ' +
            'Our strengths include platform optimization, customer journey mapping, and analytics-driven marketing ' +
            'strategies.'
    }, {
        Icon: Svg.Retail,
        title: 'Retail',
        description: 'Our retail expertise combines in-store and digital strategies to elevate customer experiences.' +
            ' We focus on inventory management, customer analytics, and omnichannel marketing to drive sales and brand' +
            ' loyalty.'
    }, {
        Icon: Svg.Entertainment,
        title: 'Financial Services',
        description: 'In financial services, we focus on security, compliance, and user experience. Our expertise' +
            ' spans digital transformation, risk management, and customer service innovations to enhance financial' +
            ' health and access.'
    },
]
import React from 'react';
import {motion} from 'framer-motion'
import {Images} from "assets/images";

export const MobileApplicationsPolo = () => {

    const animationVariant = {
        visible: {
            scale: 1,
            opacity: 1
        },

        hidden: {
            scale: .8,
            opacity: 0
        }
    }

    return (
        <div className="mobile-applications-polo-wrapper">

            <div className="container">
                <div className="mobile-applications-therapy-lake-box">
                    <h2 className='mobile-applications-therapy-lake-title'>
                        IOS and Android Application
                    </h2>

                    <div className="mobile-applications-therapy-lake-screens-block">
                        <motion.div
                            className="mobile-applications-therapy-lake-screen-1"
                            initial="hidden"
                            whileInView='visible'
                            variants={animationVariant}
                            viewport={{amount: .2, once: true}}
                            transition={{
                                duration: 1,
                                ease: "easeInOut",
                                repeatDelay: 1
                            }}
                        >
                            <img src={Images.PoloMobileScreen1} alt="Iphone 15"/>
                        </motion.div>
                        <motion.div
                            className="mobile-applications-therapy-lake-screen-2"
                            initial="hidden"
                            whileInView='visible'
                            variants={animationVariant}
                            viewport={{amount: .2, once:true}}
                            transition={{
                                duration: 1,
                                ease: "easeInOut",
                                repeatDelay: 1
                            }}
                        >
                            <img src={Images.PoloMobileScreen2} alt="Iphone 14"/>
                        </motion.div>
                        <motion.div
                            className="mobile-applications-therapy-lake-screen-3"
                            initial="hidden"
                            whileInView='visible'
                            variants={animationVariant}
                            viewport={{amount: .2, once:true}}
                            transition={{
                                duration: 1,
                                ease: "easeInOut",
                                repeatDelay: 1
                            }}
                        >
                            <img src={Images.PoloMobileScreen3} alt="Iphone 13"/>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};


import React from 'react';
import {AnimationAndText, Button} from "components";
import {CardWithImage} from "components/card";
import {membersCard} from "./constants";
import {useNavigate} from "react-router";
import Carousel from "nuka-carousel";

export const OurTeamMembers = () => {

    return (
        <div className='our-team-members-wrapper'>
            <div className="container">
                <AnimationAndText
                    text='Our Team Members'
                    className='our-team-members-animation-and-text'
                />

                <div className="members-cards">
                    {membersCard.map(({image,imageHovered, title, description}, i) => (
                        <CardWithImage
                            key={i}
                            className='member-single-card'
                            title={title}
                            image={image}
                            imageHovered={imageHovered}
                            description={description}
                        />
                    ))}

                </div>

                <div className="members-cards-mobile">
                    <Carousel
                        cellAlign="center"
                        slidesToShow={1}
                        cellSpacing={10}
                        slideIndex={1}
                    >
                        {membersCard.map(({image, title, description}, i) => (
                            <CardWithImage
                                key={i}
                                className='member-single-card'
                                title={title}
                                image={image}
                                description={description}
                            />
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};


export const services = [
    {
        name:'Web Apps',
        href:'services#web-app',
    },
    {
        name:'AI Apps',
        href:'services#ai-apps',
    },
    {
        name:'Mobile Apps',
        href:'services#mobile-apps',
    },
    {
        name:'Integrations',
        href:'services#integrations',
    },
    {
        name:'Automation Software',
        href:'services#aut-soft',
    },
]



export const company = [
    {
        name:'Contact Us',
        href:'/contact-us',
    },
    {
        name:'About Us',
        href:'/about-us',
    },
    // {
    //     name:'Blog',
    //     href:'/blog',
    // },

]
import React from 'react';
import {UserPersonasCard} from "components";

export const UserPersonas = ({className, userPersonasCard}) => {
    const animVariant = {
        visible: {
            scale: 1,
            opacity: 1
        },

        hidden: {
            scale: .9,
            opacity: .8
        }
    }

    return (
        <div className={`user-personas-section ${className ? className : ''}`}>
            <h2 className='user-personas-section-title'>
                User personas
            </h2>

            <div className="container">
                <div className="user-personas-section-cards">
                    {userPersonasCard.map(({userInfo, story, goals, painPoints, className}, i) => (
                        <UserPersonasCard
                            key={i}
                            initial="hidden"
                            whileInView='visible'
                            variants={animVariant}
                            viewport={{amount: .2, once: true}}
                            transition={{
                                duration: 1,
                                ease: "easeInOut",
                                repeatDelay: 1
                            }}
                            userInfo={userInfo}
                            story={story}
                            goals={goals}
                            painPoints={painPoints}
                            className={className}
                        />
                    ))}
                </div>
            </div>

        </div>
    );
};


import React from 'react';
import {motion} from 'framer-motion'
import {Svg, Images} from "assets/images";

export const BannerPolo = () => {
    const animationVariant = {
        visible: {
            y: 0,
            opacity: 1,
        },

        hidden: {
            y: 50,
            opacity: 1,
        }
    }

    const animImageVariant = {
        visible: {
            scale: 1,
            opacity: 1,
        },

        hidden: {
            scale: .5,
            opacity: 1,
        }
    }

    return (
        <div className='polo-banner-wrapper'>
            <div className="container">

                <div className='polo-banner-box'>
                    <div className='overflow-hidden'>
                        <motion.div
                            className="polo-banner-content"

                        >
                            <Svg.PoloLogo className="polo-banner-svg"/>

                            <motion.h3
                                className='polo-banner-title'
                                initial="hidden"
                                whileInView='visible'
                                variants={animationVariant}
                                viewport={{amount: .2, once: true}}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                    repeatDelay: 1
                                }}
                            >
                                PoloTMS: Navigating Logistics with Precision
                            </motion.h3>

                            <motion.p
                                className='polo-banner-desc'
                                initial="hidden"
                                whileInView='visible'
                                variants={animationVariant}
                                viewport={{amount: .2, once: true}}
                                transition={{
                                    duration: 1,
                                    delay: .1,
                                    ease: "easeInOut",
                                    repeatDelay: 1
                                }}
                            >
                                Empowering Logistics Operations with a Powerful Platform Featuring Advanced
                                Integration and Automations for FTL, LTL, and 3PL Services Provider
                            </motion.p>
                        </motion.div>
                    </div>

                    <motion.div
                        className='polo-banner-img'
                        initial="hidden"
                        whileInView='visible'
                        variants={animImageVariant}
                        viewport={{amount: .2, once: true}}
                        transition={{
                            duration: 1,
                            ease: "easeInOut",
                            repeatDelay: 1
                        }}
                    >
                        <img src={Images.PoloBannerImg} alt="banner"/>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};


import React, {forwardRef} from 'react';
import {motion} from 'framer-motion'

export const MUserPersonasCard = forwardRef(({userInfo, story, goals, painPoints, className}, ref) => {

    return (
        <div
            ref={ref}
            className={`user-personas-wrapper ${className ? className : ''}`}
        >
            <div className="user-personas-info">
                <div className='user-personas-image'>
                    <img src={userInfo.userImg} alt="user"/>
                </div>

                <div className="user-personas-fName-profession-year-old">
                    <h4 className="user-personas-fName">
                        {userInfo.fullName}
                    </h4>

                    <p className='user-personas-profession'>
                        {userInfo.profession}
                    </p>

                    <p className='user-personas-year-old'>
                        {userInfo.yearOld}
                    </p>
                </div>
            </div>

            <div className="user-personas-story">
                <h4 className='user-personas-story-title'>
                    User Story
                </h4>

                <p className='user-personas-story-text'>
                    {story}
                </p>
            </div>

            <div className="user-personas-goals-wrapper">
                <h4 className='user-personas-goals-title'>
                    Goals
                </h4>

                <ul className='user-personas-goals-block'>
                    {goals.map((goal, i) => (
                        <li className='user-personas-goals-list' key={i}>
                            {goal}
                        </li>
                    ))}
                </ul>
            </div>

            <div className="user-personas-pain-points-wrapper">
                <h4 className='user-personas-pain-points-title'>
                    Pain Points
                </h4>

                <ul className='user-personas-pain-points-block'>
                    {painPoints.map((goal, i) => (
                        <li className='user-personas-pain-points-list' key={i}>
                            {goal}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
});

export const UserPersonasCard = motion(MUserPersonasCard)
import React from 'react';
import {
    BannerBy,
    ChallengesForProjects,
    InfoAboutProjects,
    LogoDesignBy,
    ProblemAndSolutionCards,
    TheBriefBy, UiDesignBy
} from "../fragments";
import {Images} from "assets/images";
import {byProblemAndSolutionCards, challengesCardsBy} from "../fragments/by/constants";
import {AboutSomething} from "fragments";
import {StrategyCreativity} from "components";

export const ByPage = () => {
    return (
        <div className="by-page">
            <BannerBy/>
            <InfoAboutProjects
                className='by-info-about-projects'
                client={["B.Y. Consulting & Management Services"]}
                industry={["Healthcare Management"]}
                deliverables={["UI/UX Design", "Logo Design", "Website Development"]}
            />

            <TheBriefBy/>

            <div className="by-wrapper-problem-and-solution">
                <ProblemAndSolutionCards
                    className='problem-and-solution-by'
                    cards={byProblemAndSolutionCards}
                />
            </div>

            <ChallengesForProjects
                classNameWrapper="challenges-by"
                cards={challengesCardsBy}
                challengesForProjectsTitle="Challenges"
            />

            <LogoDesignBy/>

            <UiDesignBy/>

            <AboutSomething
                className="about-something-by-wrapper"
                img={Images.ConclusionBy}
                title="Conclusion"
                description="The business is an online calculator for transportation companies for finding
                profitability loads. Companies can add their own trucks and some information about trucks. Next,
                 a company can add loads in the system and select trucks from its own list, after which the system
                  will calculate the profit."
            />

            <StrategyCreativity background="primary"/>
        </div>
    );
};


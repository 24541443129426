import React, {useState} from 'react';
import {motion} from 'framer-motion'
import {tabs} from './constants'

export const WeUse = () => {
    const [currentTab, toggleTab] = useState(tabs[0].title)

    const changeTab = (title) => {
        toggleTab(title)
    }
    const animationVariant = {
        hidden: {
            opacity: 0,
            x: Math.random() * 100,
            Y: Math.random() * 100,
        },

        visible: {
            opacity: 1,
            x: 0,
            Y: 0,
        },

    }
    return (
        <div className='we-wse-wrapper'>
            <div className="container">
                <div className="we-wse-box">
                    <h2 className='we-wse-title'>
                        Technologies We Use
                    </h2>

                    <div className="we-wse-content">
                        <div className="we-wse-tab-block">
                            {tabs.map(({title}, i) => (
                                <motion.div
                                    key={i}
                                    className={`tab-title ${currentTab === title ? 'active-tab-title' : ''}`}
                                    onClick={() => changeTab(title)}
                                    whileHover={{scale: 1.05, translateY: 0}}
                                    whileTap={{scale: 0.95, opacity: .8}}
                                    transition={{duration: .1}}
                                >
                                    {title}
                                </motion.div>
                            ))}
                        </div>

                        <div className="we-wse-tab-items-block">
                            {tabs.find(({title}) => title === currentTab).images
                                .map(({title, Svg}, i) => {
                                    return (
                                        <motion.div
                                            key={i}
                                            className='we-wse-tab-item-block'
                                            initial="hidden"
                                            whileInView='visible'
                                            variants={animationVariant}
                                            viewport={{amount: .2, once: true}}

                                        >
                                            <div className='we-wse-tab-item'>
                                                <Svg/>
                                            </div>

                                            <p className='we-wse-tab-text'>
                                                {title}
                                            </p>
                                        </motion.div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


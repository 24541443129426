import React from 'react';
import {Images} from "assets/images";
import {motion} from 'framer-motion'
import {useWindowSize} from "utils/hooks";

export const UiDesignMenuMango = () => {
    const [width] = useWindowSize()

    const renderImage = () => {
        if (width > 778) {
            return Images.MenuMangoScreens1440
        } else if (width > 375) {
            return Images.MenuMangoScreens778
        } else {
            return Images.MenuMangoScreens375
        }
    }

    const animTitleVariant = {
        visible: {
            y: 0,
            opacity: 1,

        },
        hidden: {
            y: 50,
            opacity: 0,
        }
    }


    const animaScreenVariant = {
        visible: {
            scale: 1,
            opacity: 1,

        },
        hidden: {
            scale: .8,
            opacity: .5,
        }
    }


    return (
        <div className='ui-design-menu-mango-wrapper'>
            <motion.h2
                className='ui-design-menu-mango-title'
                initial="hidden"
                whileInView='visible'
                variants={animTitleVariant}
                viewport={{amount: .2, once: true}}
                transition={{
                    duration: 1,
                    ease: "easeInOut",
                }}
            >
                UI Design
            </motion.h2>

            <motion.div
                className='ui-design-menu-mango-macBook-screen'
                initial="hidden"
                whileInView='visible'
                variants={animaScreenVariant}
                viewport={{amount: .2, once: true}}
                transition={{
                    duration: 1,
                    ease: "easeInOut",
                }}
            >
                <img src={Images.MenuMangoMacbook} alt="MacBook"/>
            </motion.div>

            <div
                className='ui-design-menu-mango-screens'
            >
                <img src={renderImage()} alt="screens"/>
            </div>
        </div>
    );
};



import React, {useState} from 'react';
import _ from 'lodash'
import {Button} from "components";


export const CardWithImage = ({image, imageHovered, title, description, buttons, additionalData, className, onClick}) => {
    const [isHovered, toggleHover] = useState(image)

    return (
        <div
            className={`card-with-image-wrapper ${className ? className : ''}`}
            onMouseEnter={() => imageHovered && toggleHover(imageHovered)}
            onMouseLeave={() => imageHovered && toggleHover(image)}
             onClick={onClick}
        >
            <div className="card-image-box">
                <img src={isHovered} alt="card-user"/>
            </div>

            <div className="card-with-image-content">
                <h3 className='card-with-image-title'>
                    {title}
                </h3>

                <p className='card-with-image-description'>
                    {description}
                </p>

                {additionalData &&
                    <div className="additional-data-block">
                        {additionalData.map((item) => (
                            <p className='additional-data-item' key={item}>
                                {item}
                            </p>
                        ))}
                    </div>
                }

                {buttons &&
                    <div className="buttons-block">
                        {_.map(buttons, ({text, onClick, className}) => (
                            <Button
                                key={text}
                                allowIcon={false}
                                text={text}
                                onClick={onClick}
                                className={`card-button ${className}`}
                            />
                        ))}
                    </div>
                }
            </div>


        </div>
    );
};


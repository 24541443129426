import React from 'react';
import {motion} from 'framer-motion'
import {Images} from "assets/images";
import {IsInView} from "utils";

export const ProjectProcess = () => {

   const animationVariant = {
        visible: {
            scale: 1,
            opacity: 1
        },

        hidden: {
            scale: .9,
            opacity: .5
        }
    }

    const animationTitle = {
        visible: {
            y: 0,
        },

        hidden: {
            y: 25,
        }
    }

    return (
        <div className='menu-mango-project-process-wrapper'>
            <div className="container">
                <div className="menu-mango-project-process-box">
                    <div className='overflow-hidden'>
                        <motion.h2
                            className='menu-mango-project-process-title'
                            initial="hidden"
                            whileInView='visible'
                            viewport={{amount: .2, once: true}}
                            variants={animationTitle}
                            transition={{
                                duration: 1,
                                ease: "easeInOut",
                            }}
                        >
                            Project Process
                        </motion.h2>
                    </div>

                    <motion.div
                        className="menu-mango-project-process-image"
                        initial="hidden"
                        whileInView='visible'
                        variants={animationVariant}
                        viewport={{amount: .2, once: true}}
                        transition={{
                            duration: 1,
                            ease: "easeInOut",
                        }}
                    >
                        <img
                            src={Images.MenuMangoProjectProcess}
                            alt="Project Process"
                        />
                    </motion.div>
                </div>
            </div>
        </div>
    );
};



import React, {useId} from 'react';
import {forwardRef} from 'react'
import {FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel} from "@mui/material";
import {useController} from "react-hook-form";


export const InputMain = forwardRef((
    {
        onClickButton,
        name,
        value,
        type = 'text',
        placeholder,
        label,
        disabled,
        classNameWrapper,
        required,
        error = '',
        FirstIcon,
        SecondIcon,
        showPassword,
        endAdornment = false,
        startAdornment = false,
        control = {},
        rules,
    }, ref
) => {
    const {field = {}, fieldState = {}} = useController({name, control, rules});

    const id = useId()

    return (
        <div className={`main-input-wrapper ${classNameWrapper ? classNameWrapper : ''}`}>
            <FormControl
                variant="standard"
                error={error || fieldState?.error?.message}
                disabled={disabled}
            >
                <InputLabel htmlFor={`standard-adornment-${type}-${id}`}>
                    {label}
                </InputLabel>

                <Input
                    id={`standard-adornment-${type}-${id}`}
                    {...field}
                    error={!!fieldState?.error?.message}
                    inputRef={ref}
                    defaultValue={value}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    endAdornment={endAdornment &&
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={onClickButton}

                            >
                                {type === 'password' ? showPassword ? <FirstIcon/> : <SecondIcon/> : <FirstIcon/>}
                            </IconButton>
                        </InputAdornment>
                    }
                    startAdornment={startAdornment &&
                        <InputAdornment position="start">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={onClickButton}

                            >
                                {<FirstIcon/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <p className='input-error-text' >{error || fieldState?.error?.message}</p>
            </FormControl>
        </div>

    );
});


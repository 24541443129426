import React, {useEffect, useRef, useState} from 'react';
import {animate, motion, useInView, useMotionValue, useTransform} from "framer-motion";

export const Counter = ({from, to}) => {
    const count = useMotionValue(from);
    const [animationComplete, setAnimationComplete] = useState(false);

    const rounded = useTransform(count, (latest) => {
        return latest.toFixed(0);
    });

    const ref = useRef(null);
    const inView = useInView(ref);

    // useEffect(() => {
    //     if (inView) {
    //         count.setCurrent(from)
    //     }
    // }, [inView]);

    useEffect(() => {
        if (inView) {
            animate(count, to,
                {
                    duration: 3,
                    ease: "easeInOut",
                    repeat: 0,
                    onComplete: () => setAnimationComplete(true)
                });
        }
    }, [count, inView, to]);

    return <motion.span ref={ref}>{animationComplete ? to : rounded}</motion.span>;
};


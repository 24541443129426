import React from 'react';
import {
    BannerStarfish, ChallengesForProjects,
    InfoAboutProjects,
    ProblemAndSolutionCards, ScreensStarfish, UiUxDesignForStarfish, WorkflowStarfish
} from "../fragments";
import {Images} from "assets/images";
import {StrategyCreativity} from "components";
import {challengesCardsStarfish, starfishProblemAndSolutionCards} from "../fragments/starfish/constants";
import {AboutSomething} from "fragments";


export const Starfish = () => {
    return (
        <div className='starfish-page'>
            <BannerStarfish/>
            <InfoAboutProjects
                client={["Starfish"]}
                industry={["Ecommerce"]}
                deliverables={[
                    "Web App",
                    "UI/UX Design",
                    "Architectue and integrations"
                ]}
            />
            <AboutSomething
                className="about-something-starfish"
                title='About the Platform'
                description='Starfish is a cloud-based software system designed to facilitate seamless connectivity
                     and integration among prominent online platforms, including Amazon, eBay, Walmart, Google, and
                     Erply. The primary purpose of Starfish is to ensure the synchronization of product quantities and
                     the streamlined creation of orders across these platforms.'
                secondDescription='In this system, Erply serves as the central inventory management system
                     responsible for housing all product information. Notably, Erply possesses the capability to
                      accommodate multiple locations, each featuring distinct product quantities. Within this
                       framework, Starfish is strategically configured to interface with a designated Erply location,
                        diligently monitoring and managing product quantities within that specific location.'
                link={{name: 'Visit website', href: './'}}
                img={Images.AboutPlatform}/>

            <div className='problem-and-solution-cards-wrapper-starfish'>
                <ProblemAndSolutionCards
                    cards={starfishProblemAndSolutionCards}
                />
            </div>

            <ChallengesForProjects
                challengesForProjectsTitle="Challenges"
                cards={challengesCardsStarfish}
            />

            <WorkflowStarfish/>

            <UiUxDesignForStarfish/>

            <ScreensStarfish/>

            <AboutSomething
                className="about-something-starfish conclusion-starfish"
                classNameWrapper='starfish-conclusion-wrapper'
                title='Conclusion'
                description="Integrating a comprehensive system like Starfish is crucial for online businesses to
                 avoid inventory mishaps, order mix-ups, and the challenges of adhering to various platform regulations.
                  This advanced system ensures efficient product and order management, quick and accurate updates,
                   and more informed decision-making. Essentially acting as an organizational ally, it's key to
                    maintaining smooth operations, satisfying customers, and driving business growth."
                img={Images.StarfishConclusion}/>

            <StrategyCreativity/>
        </div>
    );
};


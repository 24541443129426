import React from 'react';
import {TermsPrivacyPolicy} from "fragments/termsPrivacyPolicy";
import {terms} from "./fragments/constants";

export const Terms = () => {
    return (
        <div className='terms-page'>
            <TermsPrivacyPolicy item={terms}/>
        </div>
    );
};


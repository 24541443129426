import React from 'react';
import {motion} from 'framer-motion'
import {useNavigate} from "react-router";
import {Counter, Square} from "fragments";
import {Button} from "components";


const titleAnimVariant = {
    hidden: {
        y: 50,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1
    }
}
const countAnimVariant = {
    hidden: {
        from: 0,
    },
    visible: {
        to: 400
    }
}


export const Professionalism = () => {
    const navigate = useNavigate()

    return (
        <div className='prof-wrapper'>
            <div className='yellow-square'/>

            <div className='white-square'/>

            <div className="container">
                <div className='prof-box'>
                    <div className='prof-box_titles-block'>
                        <motion.h2
                            className='title'
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: .2, once: true}}
                            variants={titleAnimVariant}
                            transition={{
                                duration: .9,
                                ease: "easeInOut",
                            }}
                        >
                            Expertise.
                        </motion.h2>

                        <motion.h2
                            className='title'
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: .2, once: true}}
                            variants={titleAnimVariant}
                            transition={{
                                duration: .9,
                                delay: .1,
                                ease: "easeInOut",
                            }}
                        >
                            Transparency.
                        </motion.h2>

                        <motion.h2
                            className='title'
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: .2, once: true}}
                            variants={titleAnimVariant}
                            transition={{
                                duration: .9,
                                delay: .2,
                                ease: "easeInOut",
                            }}
                        >
                            Results.
                        </motion.h2>
                    </div>

                    <div className='right-content'>
                        <div className='right-content-for-us'>
                            <div
                                className='right-content-for-us-count-block'
                            >
                                <p className='right-content-for-us-count'>
                                    <Counter from={0} to={47}/>
                                </p>

                                <p className='right-content-for-us-text'>
                                    Projects completed
                                </p>
                            </div>

                            <div className='right-content-for-us-count-block'>
                                <p className='right-content-for-us-count'>
                                    <Counter from={0} to={99.9}/>%
                                </p>

                                <p className='right-content-for-us-text'>
                                    + Uptime
                                </p>
                            </div>

                            <div
                                className='right-content-for-us-count-block'>
                                <p className='right-content-for-us-count'>
                                    <Counter from={0} to={6}/>+
                                </p>

                                <p className='right-content-for-us-text'>
                                    Years of Experience
                                </p>
                            </div>

                            <div
                                className='right-content-for-us-count-block'
                            >
                                <p className='right-content-for-us-count'>
                                    <Counter from={0} to={95}/>%+
                                </p>

                                <p
                                    style={{minWidth: 200}}
                                    className='right-content-for-us-text'>
                                    Repeat or referral business
                                </p>
                            </div>
                        </div>

                        <Button
                            color='#000'
                            background='#FEDA00'
                            text='Let’s Discuss'
                            onClick={() => navigate('contact-us')}
                            className='lets-discuss'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};


import React from 'react';
import {motion} from 'framer-motion'
import {Svg, Images} from "assets/images";
import {InfoAboutProjects} from "../InfoAboutProjects";
import {useWindowSize} from "utils/hooks";

export const BannerTherapyLakeAndInfo = () => {
    const [width] = useWindowSize()

    const animationVariant = {
        visible: {
            y: 0,
            opacity: 1
        },

        hidden: {
            y: 70,
            opacity: 0
        }
    }

    return (
        <div className="banner-and-info-therapy-lake">
            <div className='banner-therapy-lake-wrapper'>
                <div className='overflow-hidden'>
                    <motion.div
                        className="therapy-lake-logo-title-description-block"
                        initial="hidden"
                        whileInView='visible'
                        variants={animationVariant}
                        viewport={{amount: .2, once: true}}
                        transition={{
                            duration: 1,
                            ease: "easeInOut",
                            repeatDelay: 1
                        }}
                    >
                        <Svg.TherapyLakeLogo className='therapy-lake-logo'/>

                        <h2 className='therapy-lake-title'>
                            Optimize healthcare practice management
                        </h2>

                        <p className="therapy-lake-description">
                            Simplify client onboarding, enhance staff management, and optimize scheduling and
                            billing—all
                            in one integrated platform. Elevate your service delivery with TherapyLake, where efficiency
                            meets clinical excellence.
                        </p>
                    </motion.div>
                </div>

                <div className="banner-therapy-banner-image">
                    <img src={width > 375 ? Images.TherapyLakeBanner : Images.TherapyLakeBanner375}
                         alt="Therapy Banner"/>
                </div>
            </div>

            <InfoAboutProjects
                fontColor="#FFF"
                className='therapy-lake-info-about-projects'
                client={["Therapy"]}
                industry={["Healthcare"]}
                deliverables={["UI/UX Design", "Branding and Identity", "Web Development", "IOS and Android Development"]}
            />
        </div>

    );
};


import React from 'react';
import {Images} from "assets/images";

export const WorkflowTherapyLake = () => {
    return (
        <div className='workflow-therapy-lake-wrapper'>
            <div className="container">
                <div className="workflow-therapy-lake-box">
                    <h2 className="workflow-therapy-lake-title">
                        Workflow
                    </h2>

                    <div className="workflow-therapy-lake-image-box">
                        <img src={Images.TherapyLakeWorkflow} alt="Workflow"/>
                    </div>
                </div>
            </div>
        </div>
    );
};


import {Routers} from "root";


function App() {
    return (
        <div className='routers-wrapper'>
            <Routers/>
        </div>
    );
}

export default App;

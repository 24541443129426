import React, {forwardRef} from 'react';
import {motion} from "framer-motion";

const MCard = forwardRef((
    {title, text, color, lineWidth, lineColor, backgroundColor, className, Icon, userInfo, numbered}, ref
) => {

    const animationVariantBlock = {
        visible: {
            scale: 1,
            opacity: 1,
        },
        hidden: {
            scale: .8,
            opacity: 0,
        }
    }


    return (
        <motion.div
            ref={ref}
            initial="hidden"
            whileInView='visible'
            variants={animationVariantBlock}
            viewport={{amount: .2, once: true}}
            transition={{duration: .5}}
            className={`wrapper ${numbered ? 'numbered-card' : ''} ${className ? className : ''}`}
            style={{backgroundColor: backgroundColor ? backgroundColor : ''}}
        >
            {numbered &&
                <div className='numbered'>
                    {numbered}
                </div>
            }
            <div className='card-content-wrapper'>
                {Icon && <Icon/>}

                {title &&
                    <h3
                        className='title'
                        style={{
                            color: color ? color : '',
                        }}
                    >
                        {title}
                    </h3>
                }


                <div
                    style={{width: lineWidth, backgroundColor: lineColor}} className='line'
                />

                <p
                    className='text'
                    style={{color: color ? color : ''}}>
                    {text}
                </p>


                {userInfo &&
                    <div className="user-info">
                        <div className="user-img">
                            {<userInfo.img />}
                        </div>
                        <div className="name-position">
                            <p className='user-name'>{userInfo?.name}</p>
                        </div>
                    </div>}
            </div>
        </motion.div>
    );
});

export const Card = motion(MCard)



import React from 'react';

export const TermsPrivacyPolicy = ({item}) => {
    return (
        <div className="terms-privacy-policy-wrapper">
            <div className="container">
                <div className="terms-privacy-policy-title">
                    {item.title}
                </div>

                <div className="terms-privacy-policy-box">
                    <p className="terms-privacy-policy-box-content-title">
                        {item.boxTitle}
                    </p>

                    <ul className="terms-privacy-policy-block">
                        {item.list.map((listItem) => (
                            <li className="terms-privacy-policy-item">
                                <p className="terms-privacy-policy-description">
                                    <span className="terms-privacy-policy-item-title">
                                        {listItem.title}
                                    </span>
                                    {listItem.description}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};


import React from 'react';
import {Images} from "assets/images";
import {useWindowSize} from "../../../../utils/hooks";

export const LogoDesignAeco = () => {

    const [width] = useWindowSize()

    const renderImage = () => {
        if (width > 1279) {
            return Images.LogoDesignAeco1440
        } else if (width > 767) {
            return Images.LogoDesignAeco767
        } else {
            return Images.LogoDesignAeco345
        }
    }

    const imageSize = () => {
        if (width > 1279) {
            return {
                width: "100%",
                height: 1285
            }
        } else if (width > 767) {
            return {
                maxWidth: 698,
                height: 941
            }
        } else {
            return {
                maxWidth: 343,
                height: 1176
            }
        }
    }


    return (
        <div className="logo-design-aeco-wrapper">
            <h2 className="logo-design-aeco-title">
                Logo Design
            </h2>

            <div className="logo-design-aeco-box">
                <div className="logo-design-aeco-img-block" style={{...imageSize()}}>
                    <img src={renderImage()} alt="logo and design"/>
                </div>

                <div className="images-helmet-logo-block-wrapper">
                    <div className="helmet-image-block">
                        <img src={Images.HelmetAeco} alt="helmet"/>
                    </div>

                    <div className="logo-image-block">
                        <img src={Images.LogoBlockAeco} alt="helmet"/>
                    </div>
                </div>
            </div>

            <div className="screens-aeco-wrapper">
                <div className="container">
                    <div className="macbook-screen-aeco">
                        <img src={Images.MacbookAeco} alt="macbook screen"/>
                    </div>

                    <div className="home-page-screens-block-aeco">
                        <div className="home-page-web-screen-aeco">
                            <img src={Images.HomePageScreenAeco} alt="web home page"/>
                        </div>

                        <div className="home-page-mobile-screen-aeco">
                            <img src={Images.HomePageMobileScreenAeco} alt="mobile-home-page"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


import React from 'react';
import {motion} from "framer-motion";
import {Button} from "components";
import {Square} from "fragments";
import {useNavigate} from "react-router";


const textAnimVariant = {
    visible: {
        opacity: 1,
        y: 0
    },
    hidden: {
        opacity: 0,
        y: 50
    }
}
export const StrategyCreativity = ({overflowHidden = true}) => {
    const navigate = useNavigate()

    return (
        <div className={`strategy-wrapper`}
             style={{
                 overflow: overflowHidden ? 'hidden' : 'visible'
             }}
        >
            <div className="position-absolute">
                <div
                    className='green-square'
                />

                <div className="container">
                    <div className='strategy-box'>
                        <div className='blue-square'/>

                        <div style={{width: '100%'}}>
                            <div className='overflow-hidden'>
                                <motion.h2
                                    className='title'
                                    initial="hidden"
                                    whileInView='visible'
                                    viewport={{amount: .2, once: true}}
                                    variants={textAnimVariant}
                                    transition={{
                                        duration: .8,
                                        ease: "easeInOut",
                                    }}
                                >
                                    Strategy-led creativity, a human-centered approach, and commitment to results.
                                </motion.h2>
                            </div>

                            <div className='overflow-hidden'>
                                <motion.p
                                    className='text'
                                    initial="hidden"
                                    whileInView='visible'
                                    viewport={{amount: .2, once: true}}
                                    variants={textAnimVariant}
                                    transition={{
                                        duration: .8,
                                        ease: "easeInOut",
                                    }}
                                >
                                    Everything we do is intersected by our love for brands, design and innovation
                                </motion.p>
                            </div>

                            <Button
                                color='#FFF'
                                background="#2A2F35"
                                text='Get In Touch'
                                className='go-it-btn'
                                onClick={() => navigate('/contact-us')}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};


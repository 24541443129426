export const privacyPolicy = {
    title: "Privacy Policy",
    boxTitle: "Please read these terms and conditions carefully before using Our Service.",
    list: [
        {
            title: "Introduction:",
            description: "These Terms and Conditions govern your use of our truck management software. By using our" +
                " software, you agree to be bound by these Terms and Conditions. If you do not agree to these Terms " +
                "and Conditions, you must not use our software."
        },
        {
            title: "Use of Software:",
            description:" Our software is designed to help manage truck fleets, track routes and deliveries, and " +
                "optimize operations. You may use our software only for lawful purposes and in accordance with these " +
                "Terms and Conditions. You must not use our software in any way that violates any applicable federal," +
                " state, local, or international law or regulation"
        },
        {
            title: "Use of Software:",
            description:"Our software and its entire contents, features, and functionality are owned by us or" +
                " our licensors and are protected by copyright, trademark, and other intellectual property or" +
                " proprietary rights laws."
        },
        {
            title: "Disclaimer of Warranties:",
            description:"We do not guarantee that our software will be uninterrupted, error-free, or free from " +
                "viruses or other harmful components. We make no warranties or representations about the accuracy" +
                " or completeness of the content provided through our software."
        },
        {
            title: "Limitation of Liability:",
            description:"In no event shall we be liable for any indirect, incidental, special, or consequential" +
                " damages arising out of or in connection with your use of our software."
        },
        {
            title: "Governing Law:",
            description:" These Terms and Conditions shall be governed by and construed in accordance with" +
                " the laws of [state/country], without giving effect to any choice of law or conflict of law provisions."
        },
        {
            title: "Termination: ",
            description:"We may terminate or suspend your access to our software immediately, without prior" +
                " notice or liability, for any reason whatsoever, including without limitation if you breach these " +
                "Terms and Conditions."
        },
    ]
}
import {Svg} from "assets/images";

export const poloProblemAndSolutionCards = [
    {
        Icon: Svg.StarfishProblemIcon,
        title: 'Problem',
        text: "The client's logistics operations were mired in inefficiencies due " +
            "to reliance on manual tasks and physical workbooks, leading to challenges in" +
            " access management, data loss, and frequent data entry errors. These issues compounded," +
            " affecting operational speed, accuracy, and ultimately, customer satisfaction."
    },
    {
        Icon: Svg.StarfishSolutionIcon,
        title: "Solution",
        text:"PoloTMS was developed to address these pain points, offering a comprehensive platform that not only " +
            "automates manual operations but also centralizes data for improved access and management. Enhanced with" +
            " advanced integrations and automation features, PoloTMS significantly reduces data handling errors," +
            " streamlines processes, and ensures data integrity and security, paving the way for a more efficient and " +
            "reliable logistics workflow."
    }
]

export const challengesCardsPolo = [
    {
        className:'',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Integration of Diverse Operational Activities",
        text:"Merging various logistics functions into a unified system while maintaining high performance and reliability."
    },
    {
        className:'',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Automating Complex Financial Transactions",
        text:"Developing a seamless automation process for intricate financial operations such as profit" +
            " sharing with agents and carriers, including factoring services."
    },
    {
        className:'',
        Icon:Svg.ChallengesStarfishSvg,
        title:"Advanced Permission Systems for Data Security",
        text:"Implementing robust permission controls to safeguard sensitive operational data against" +
            " unauthorized access, ensuring security and compliance."
    },

]

import React from 'react';
import {Svg} from "assets/images";
import {ListItem, ListItemButton, ListItemText} from "@mui/material";
import {useLocation} from "react-router";

export const MainMenuList = ({item, toggleDrawer, isOpenSubOption}) => {
    const location = useLocation()
    const activeOption = location.hash ? `${location?.pathname}${location?.hash}` : `${location?.pathname}`
    const link = `/${item?.link}`

    return (
        <ListItem
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
        >
            <ListItemButton>
                <ListItemText
                    className={activeOption.includes(link) ? 'activeOption' : ''}
                    primary={item?.text}
                />
                {item?.subOption &&
                    <Svg.ArrowSvg
                        className={`menu-svg ${isOpenSubOption?.length ? 'activeOption' : ''} ${isOpenSubOption?.length ? 'open-option' : ''}`}
                    />}
            </ListItemButton>
        </ListItem>
    )
};


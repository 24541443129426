import {Fragment} from "react";
import {Navigate, Route, Routes} from "react-router";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import {
    AboutUs, Aeco,
    Blog, ByPage,
    ContactUs,
    FleetForest,
    Home,
    JoinUs, MenuMango,
    OurProjectsPage, PrivacyPolicy,
    Services,
    SingleBlog,
    Starfish, Terms, TherapyLake, Polo
} from "pages";
import {Layout} from "../fragments";


export const Routers = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="/privacy" element={<PrivacyPolicy/>}/>
                    <Route path="/about-us" element={<AboutUs/>}/>
                    <Route path="/about-us/join-us" element={<JoinUs/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/contact-us" element={<ContactUs/>}/>
                    {/*<Route path="/blog" element={<Blog/>}/>*/}
                    {/*<Route path="/blog/:blogId" element={<SingleBlog/>}/>*/}
                    <Route path="/our-projects" element={<OurProjectsPage/>}/>
                    <Route path="/our-projects/fleet-forest" element={<FleetForest/>}/>
                    <Route path="/our-projects/starfish" element={<Starfish/>}/>
                    <Route path="/our-projects/menumango" element={<MenuMango/>}/>
                    <Route path="/our-projects/therapylake" element={<TherapyLake/>}/>
                    <Route path="/our-projects/by" element={<ByPage/>}/>
                    <Route path="/our-projects/aeco" element={<Aeco/>}/>
                    <Route path="/our-projects/polo" element={<Polo/>}/>
                    <Route path="*" element={<Navigate to="/"/>}/>
                </Route>
            </Routes>

            <ToastContainer position={'bottom-right'}/>
        </Fragment>
    )
}

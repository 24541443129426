import React from 'react';
import {Button} from "components";

export const OurProjectItem = ({classNameWrapper, onClick, product}) => {
    return (
        <div
            className={`our-project-item-wrapper ${classNameWrapper ? classNameWrapper : ''} ${product?.rowReverse ? 'rowReverse' : ''} `}>
            <div className="info">
                <div>
                    <h3 className='title' style={{color: product?.titleColor}}>
                        {product.title}
                    </h3>

                    <p className='description'>
                        {product.description}
                    </p>

                    <p className="product-info">
                        {product.productInfo}
                    </p>
                </div>

                <div className="div">
                    <div className="line-our-project-item"/>

                    <Button
                        text='View More' onClick={() => onClick()}
                        className='view-more-btn'
                        color='#2A2F35'
                    />
                </div>


            </div>

            <div className="product-img">
                <img src={product.productImg} alt='product-img'/>
            </div>
        </div>
    );
};

import React from 'react';
import {motion} from "framer-motion";

export const AnimationAndText = ({text, className}) => {
    const container = {
        hidden: {
            y: 50,
            opacity: 0
        },

        visible: {
            y: 0,
            opacity: 1,
        }
    };

    return (
            <div
                className={`animated-block ${className ? className : ''}`}
            >
                <motion.p
                    className='text'
                    // initial="hidden"
                    // whileInView='visible'
                    // variants={container}
                    // transition={{
                    //     duration: .7,
                    //     ease: "easeInOut",
                    // }}
                >
                    {text}
                </motion.p>
            </div>
    );
};

